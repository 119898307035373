import React, { useState, ReactNode, useEffect } from "react";
import styled from "styled-components";

interface BlankModalProps {
  children: ReactNode;
  buttonText: string;
  headerText: string;
  className: string;
  isOpen: boolean; // New prop to control modal visibility
  onClose: () => void; // Callback function to close the modal
}

export default function BlankModal({
  children,
  buttonText,
  className,
  headerText,
  isOpen,
  onClose,
}: BlankModalProps) {
  const [showModal, setShowModal] = useState(false);

  // Use useEffect to synchronize modal state with the isOpen prop
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  // Handle modal closure via onClose callback
  const closeModal = () => {
    setShowModal(false);
    if (showModal) {
      onClose();
    }
  };
  

  return (
    <div className={className}>
      <ChangeSpotBtn type="button" onClick={() => setShowModal(true)}>
        {buttonText}
      </ChangeSpotBtn>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none">
            <div className="w-full">
              <div className="border-0 shadow-lg relative outline-none focus:outline-none bg-secondaryBg w-3/4 m-auto rounded-md">
                <div className="flex items-start justify-between p-6">
                  <h2 className="text-white">{headerText}</h2>
                  <CloseBtn style={{ width: 35, height: 35 }} onClick={closeModal}>
                    <span>×</span>
                  </CloseBtn>
                </div>
                <div className="flex-auto px-6 mb-4">
                  <div className="mt-4">{children}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-[100] bg-black" onClick={closeModal}></div>
        </>
      ) : null}
    </div>
  );
}

const CloseBtn = styled.button`
  height: 35px;
  font-size: 12px;

  border-radius: 20px;
  background: linear-gradient(90deg, #f27a54 0%, #a154f2 186.42%);
  span {
    color: white;
  }
`;

const ChangeSpotBtn = styled.button`
  font-size: 10px;
`;