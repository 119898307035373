import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

const PickDate = ({ selectedDate, setSelectedDate }) => {

  return (
    <DateWrapper>
      <DatePicker
        showIcon 
        showPopperArrow={false} 
        dateFormat="Pp"
        showTimeSelect
        selected={selectedDate} 
        onChange={(date) => setSelectedDate(date)} 
      />
    </DateWrapper>
  );
};

const DateWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
 
  input {
    border-radius: 3px;
    height: 35px;
    background-color: #30363d;
    color: #CBCFD4;
    padding-left: 2.5rem;
  }

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 0px;
    padding-left: 13px;
  }

  svg {
    padding-left: 13px;
    padding: 0px;
    height: 100%;
    fill: #CBCFD4;
  }
  
`

export default PickDate