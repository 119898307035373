import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

function SignUpValidateSuccess() {

  const [redirect, setRedirect] = useState(false);
  const [countdown, setCountdown] = useState(4);

  // Redirect the user after 3 seconds to the dashboard. They're fully verified
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRedirect(true);
    }, countdown * 1000);

    const intervalId = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    }
  }, [countdown]);
  
  return (
    <>
    {redirect && <Navigate to="/dashboard"/> }
    <Wrapper>
      <TextWidthWrapper>
        <h1>EMAIL VERIFIED!</h1>
        <p className='mt-6'>Redirecting you to the dashboard in <span>{countdown} seconds </span>where you can browse photos, videos, or create you own albums for people to see!</p>
        <a href="/dashboard"><p className='mt-6'>Didn't get redirected? <span>Go to the dashboard</span></p></a>
      </TextWidthWrapper>
    </Wrapper>
    </>
  )
}

const Wrapper = styled.div`

  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem;
  background-color: #3C444C;
  padding: 2rem;
  border-radius: 3px;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    font-size: 24px;
    font-weight: 900;
  }
`

const TextWidthWrapper = styled.div`
  margin: 0 auto;
  max-width: 760px;
  text-align: center;

  img {
    width: 105px;
  }

  span {
    color: #F27A54;
  }
`

export default SignUpValidateSuccess