import React, {useState, useEffect} from 'react';
import LoginGoogle from '../../Components/LoginGoogle/LoginGoogle';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from 'react-toastify';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../Context/UserAuthContext';
import ModalBlank from '../../Components/Modal/ModalBlank';
import 'react-toastify/dist/ReactToastify.css';

function Login() {

  const [email, setEmail] = useState("");
  const [passResetEmail, setPassResetEmal] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, currentUserState, currentUserSpot, user } = useUserAuth();
  const auth = getAuth();

  let navigate = useNavigate();

  const handleSubmit =  async (e) => {
    e.preventDefault(); 
    // setError("");
    try {
      await logIn(email, password);
      navigate('/photographers/North America/United States/Rhode Island/2nd Beach (Sachuest Beach)');
    } catch (error) {
      if (error.code == "auth/wrong-password") {
        toast.error("Password is invalid", {
          theme: "dark"
        });
      } else {
        toast.error(error.message, {
          theme: "dark"
        });
      }
    }
  }

  const handleForgotPass = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setResetSent(true);
    try {
      await sendPasswordResetEmail(auth, passResetEmail);
      toast.success("Password reset email sent", {
        theme: "dark"
      });
    } catch (error) {
      // const errorCode = error.code;
      const errorMessage = error.message;
      if (error.code === 'auth/invalid-email') {
        toast.error("An account with email was not found.", {
          theme: "dark"
        });
      } else {
        toast.error(errorMessage, {
          theme: "dark"
        });
      }
    }
    return setResetSent(false);
  }
  

  return (
    <>
    <LoginWrapper className="">  
      <div className="container max-w-md p-2">
        <div className="text-center my-6">
          <h1 className="text-3xl font-semibold text-white pb-4">FLMR</h1>
          <p className="">Sign in to access your account</p>
        </div>
        <div className="m-6">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="">
              <label className="block mb-1 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
              <input 
                type="text" 
                name="email"
                onChange={ (e) => setEmail(e.target.value) } 
                id="email" placeholder="Your email address" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mt-2 mb-4">
              <div className="flex justify-between items-center mb-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">Password</label>
                <ModalBlank 
                  buttonText='Forgot Password?' 
                  headerText='Reset Password' 
                  className='text-sm text-gray-400 focus:outline-none focus:text-indigo-500 hover:text-indigo-500 dark:hover:text-indigo-300'
                >
                  <p className="pb-4">Forgot your password? No problem, enter your email below and click submit - we will send a password reset to the provided email.</p>
                  <label className="block mb-1 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                  <input 
                    type="text" 
                    name="email"
                    onChange={(e) => setPassResetEmal(e.target.value) } 
                    id="email" 
                    placeholder="Your email address" 
                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
                  { resetSent ? <PrimaryButton disabled onClick={handleForgotPass} className="pt-8 pb-8">SEND RESET EMAIL</PrimaryButton> : <PrimaryButton onClick={handleForgotPass} className="pt-8 pb-8">SEND RESET EMAIL</PrimaryButton> }
                </ModalBlank>
              </div>
              <input  
                type="password" 
                onChange={ (e) => setPassword(e.target.value) }
                id="password" placeholder="Your password" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mb-4">
              <button className="btn-signin" type="submit">LOGIN</button>
            </div>
            <p className="text-sm text-center">
              Don't have an account yet?
              <Link to="/signup" className="font-semibold text-white focus:text-indigo-600 focus:outline-none focus:underline"> Sign up.</Link>
            </p>
          </form>
          
          {/* <div className="flex flex-row justify-center mb-8">
            <p className="absolute px-4">or sign in with</p>
            <div className="w-full bg-gray-200 mt-3 h-px"></div>
          </div> */}
          {/* <div className="flex flex-row gap-2">
            <LoginGoogle />
          </div> */}
        </div>
      </div>
    </LoginWrapper>
    </>
  )
}

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;

  .container {
    background-color: #3C444C;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 4%), 0 2px 2px hsl(0deg 0% 0% / 4%), 0 4px 4px hsl(0deg 0% 0% / 4%), 0 8px 8px hsl(0deg 0% 0% / 4%), 0 16px 16px hsl(0deg 0% 0% / 4%);
  }

    // -tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) rgb(234 118 103);
  .btn-signin {
    width: 200px;
  height: 40px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  border-radius: 7px;
  background: linear-gradient(90deg, #F27A54 0%, #A154F2 186.42%);
  letter-spacing: 1px;
  }
`

export default Login