import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type ToggleButtonProps = {
  onToggle: (value: boolean) => void;
  defaultToggled?: boolean; // Optional prop for the initial toggle state
};

const ToggleButton: React.FC<ToggleButtonProps> = ({ onToggle, defaultToggled = false }) => {
  const [isToggled, setIsToggled] = useState(defaultToggled);

  useEffect(() => {
    setIsToggled(defaultToggled);
  }, [defaultToggled]);

  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);
    onToggle(newState);
  };

  return (
    <ToggleWrapper onClick={handleToggle}>
      <div className="toggle-button-cover">
        <div className="button-cover">
          <div className="button r" id="button-1">
            <input
              type="checkbox"
              className="checkbox"
              checked={isToggled}
              readOnly // Prevents onChange from being triggered automatically
            />
            <div className="knobs"></div>
            <div className="layer"></div>
          </div>
        </div>
      </div>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.button`
   
.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 70px;
  height: 26px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #292F35;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 19px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 0;
  padding: 9px 4px;
  background-color: #CBCFD4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 46px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #292F35;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}
`

export default ToggleButton;
