import React, { useState } from 'react';

function IconCart() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = hovered ? '#F27A54' : '#31373E';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <svg width="25" height="24" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
      <path 
        d="M22.9219 13.0781L24.9737 4.0503C25.1218 3.39848 24.6263 2.77778 23.9579 2.77778H6.91007L6.51224 0.832899C6.41311 0.348134 5.98655 0 5.49171 0H1.04167C0.466363 0 0 0.466363 0 1.04167V1.73611C0 2.31141 0.466363 2.77778 1.04167 2.77778H4.07478L7.12374 17.6838C6.39431 18.1033 5.90278 18.8898 5.90278 19.7917C5.90278 21.134 6.99097 22.2222 8.33333 22.2222C9.6757 22.2222 10.7639 21.134 10.7639 19.7917C10.7639 19.1114 10.4841 18.4967 10.0337 18.0556H19.1329C18.6826 18.4967 18.4028 19.1114 18.4028 19.7917C18.4028 21.134 19.491 22.2222 20.8333 22.2222C22.1757 22.2222 23.2639 21.134 23.2639 19.7917C23.2639 18.8293 22.7045 17.9977 21.8933 17.6039L22.1327 16.5503C22.2809 15.8985 21.7854 15.2778 21.117 15.2778H9.46688L9.18281 13.8889H21.9061C22.3925 13.8889 22.8141 13.5523 22.9219 13.0781Z"
        fill={fill}
        style={svgStyle}/>
      </svg>
    </div>
  );
}

export default IconCart;