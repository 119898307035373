import React from 'react'
import styled from 'styled-components'

interface FAQProps {
  question: string,
  answer: string
}

function FAQ(props: FAQProps) {
  return (
    <FAQWrapper className="py-5 shadow-sm">
      <details className="group">
        <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
          <p><b>{props.question}</b></p>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="#CBCFD4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <p className={`group-open:animate-fadeIn transition-opacity mt-3`}>
          {props.answer}
        </p>
      </details>
    </FAQWrapper>
  );
}

const FAQWrapper = styled.div`
  background-color: #3C444C;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 7px;
  margin-bottom: .75rem;
`

export default FAQ