import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Dropdown from '../../Components/Dropdown/Dropdown';
import { toast } from 'react-toastify';

interface Booking {
  id: string;
  [key: string]: any; // To allow for dynamic fields in each booking document
}

const Admin: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsCollection = collection(db, "bookings");
        const querySnapshot = await getDocs(bookingsCollection);

        const bookingsList: Booking[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBookings(bookingsList);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        toast.error("Failed to fetch bookings", {
          theme: "dark",
        });
      }
    };

    fetchBookings();
  }, [db]);

  console.log(bookings);

  return (
    <div className="py-4 px-8">
      <h2 className="text-white">Admin - All Bookings</h2>
      <p className="pb-4">accepted_in_progress & requires_capture = Initialize Manual Pay in Stripe. Get Paid!</p>
      {/* Render booking requests */}
      {bookings.length > 0 ? (
        <div>
          {bookings.map((booking) => (
            <Dropdown 
              key={booking.id} 
              title={booking.booking_user_username} 
              bookingStatus={booking.booking_status} 
              stripeStatus={booking.stripe_status}
              >
              <div className="px-4 py-2">
                <p>Booking Fees - {booking.booking_fees}</p>
                <p>Continent - {booking.booking_continent}</p>
                <p>Booking Cost Net - {booking.booking_cost_net}</p>
                <p>Booking Cost Total - {booking.booking_cost_total}</p>
                <p>Booking User ID - {booking.booking_user_id}</p>
                <p>Booking User Username - {booking.booking_user_username}</p>
                <p>FLMR ID - {booking.flmr_id}</p>
                <p>FLMR Username - {booking.flmr_username}</p>
                <p>Stripe Metadata - {JSON.stringify(booking.metadata)}</p>
                <p>Stripe PaymentIntentId - {booking.stripe_paymentIntentId}</p>
                <p>Booking Status - {booking.booking_status}</p>
                <p>Stripe Status - {booking.stripe_status}</p>
                <p>Stripe Transaction ID - {booking.stripe_transaction_id}</p>
              </div>
            </Dropdown>
          ))}
        </div>
      ) : (
        <p>No bookings found</p>
      )}
    </div>
  );
}

export default Admin;
