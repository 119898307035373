import { useState } from "react";
import styled from "styled-components";
import SpotSelect from "./SpotSelect";

export default function Modal({ openInitially }) {
  const [showModal, setShowModal] = useState(openInitially);

  return (
    <>
      <ChangeSpotBtn type="button" onClick={() => setShowModal(true)}>
        Change Location
      </ChangeSpotBtn>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none">
            <div>
              {/*content*/}
              <div className="border-0 shadow-lg relative outline-none focus:outline-none bg-secondaryBg w-3/4 m-auto rounded-md">
                {/*header*/}
                <div className="flex items-start justify-between p-6">
                  <h2 className="text-white">Choose Location</h2>
                  <CloseBtn style={{ width: 35 }} onClick={() => setShowModal(false)}>
                    <span>×</span>
                  </CloseBtn>
                </div>
                {/*body*/}

                <div className="flex-auto px-6 mb-4">
                  <div className="mt-4">
                    <p className="mb-4">If you're trying to find a spot and don't see it, <a href="/contact" style={{ color: '#F27A54' }} target="_blank">send us a message.</a> We will add it right away. Thanks for the support!</p>
                    <SpotSelect setShowModal={setShowModal} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-[100] bg-black"></div>
        </>
      )}
    </>
  );
}

const CloseBtn = styled.button`
  height: 35px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  border-radius: 20px;
  background: linear-gradient(90deg, #F27A54 0%, #A154F2 186.42%);
`;

const ChangeSpotBtn = styled.button`
  padding: 0rem 2rem;
  height: 35px;
  font-size: 12px;
  color: white;
  border-radius: 7px;
  background: linear-gradient(90deg, #F27A54 0%, #A154F2 100%);

  @media screen and (max-width: 525px) {
    padding: 0rem 1rem;
  }
`;
