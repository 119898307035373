import React, {useState} from 'react';
import styled from "styled-components";

interface ModalProps {
  // onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDelete: () => void;
}

function Modal(props: ModalProps) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ChangeSpotBtn type="button" onClick={() => setShowModal(true)}>DELETE</ChangeSpotBtn>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none">
            <div>
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-secondaryBg w-3/4 m-auto rounded-sm">
                {/*header*/}
                <div className="flex items-start justify-between p-6">
                  <h2 className="text-white">CONFIRM DELETION</h2>
                </div>
                <div className="flex-auto px-6 mb-4">
                  <p className="font-semibold mb-2">Are you sure you want to delete this album?</p>
                  <p>This will delete all the photos/videos in the album as well as the album itself, there is no way to undo this.</p>
                  <div className="mt-4 flex">
                    <DeleteBtn onClick={() => {props.onDelete(); setShowModal(false);}}>Delete</DeleteBtn>
                    <CancelBtn onClick={() => setShowModal(false)}>Cancel</CancelBtn>
                  </div>
                </div>    
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-[100] bg-black"></div>
        </>
      ) : null}
    </>
  )
}

const CancelBtn = styled.button`
  color: #CBCFD4;
  border-radius: 3px;
  height: 30px;
  background-color: #30363d;
  margin-right: 10px;
`

const DeleteBtn = styled.button`
  color: #CBCFD4;
  border-radius: 3px;
  height: 30px;
  background-color: #30363d;
  margin-right: 10px;
`

const ChangeSpotBtn = styled.button`
  font-size: 10px;
  background-color: #30363d;
  height: 30px;
  color: white;
  font-weight: 700;
  border-radius: 7px;
  /* background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%); */
`

export default Modal