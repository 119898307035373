import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import styled from 'styled-components';
import IconUpload from '../Icons/IconUpload';
import SpotSelectOnboarding from '../SpotSelectOnboarding/SpotSelectOnboarding';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NewFlmrListing } from '../../Services/firebase/NewFlmrListing';

interface MediaTypes {
  photography: boolean;
  videography: boolean;
  drone: boolean;
}

function Stepper() {
  const [currentStep, setCurrentStep] = useState(1);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedSurfSpots, setSelectedSurfSpots] = useState([]);
  const [selectedMediaTypes, setSelectedMediaTypes] = useState<MediaTypes>({
    photography: false,
    videography: false,
    drone: false,
  });
  const [flmrDescription, setFlmrDescription] = useState('');
  let navigate = useNavigate();
  const fileTypes = ["JPG", "PNG", "MP4"];
  const { userid } = useParams();

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 6));
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleClick = (mediaType: keyof MediaTypes) => {
    setSelectedMediaTypes((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType], // Toggle the current state
    }));
  };

  const handleHourlyRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numberValue = value ? parseFloat(value).toFixed(2) : null; // Format to two decimal places, or null if empty
    setHourlyRate(numberValue ? parseFloat(numberValue) : null); // Convert back to number if not null
  }

  const handleFileChange = async (file:any) => {
    setSelectedFiles(file);
  };

  const handleSubmit = () => {
    if (!hourlyRate || !selectedMediaTypes || !selectedSurfSpots || !selectedFiles || !flmrDescription) {
      toast.error("Fill out all steps", {
        theme: "dark"
      });
      return
    }

    const mediaTypesArray: string[] = Object.entries(selectedMediaTypes)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    const profileData = {
      onboarding_completed: true,
      flmr_media_type: mediaTypesArray,
      flmr_hourly_rate: hourlyRate,
      flmr_locations: selectedSurfSpots.map(spot => spot.value),
      flmr_available: true,
      flmr_about: flmrDescription,
      is_flmr: true,
      profile_images: selectedFiles
    };

    NewFlmrListing(userid, profileData);
    navigate("/profile");
  };

  return (
    <Wrapper>
      <ol className="flex items-center w-full mb-8">
        <li className="flex w-full items-center">
          <div className={`step ${currentStep >= 1 ? 'active-step' : 'inactive-step'} ${currentStep !== 1 ? 'completed-step' : ''} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>1</p>
          </div>
          <div className={`flex-grow h-1 transition-all duration-500 ${currentStep > 1 ? 'step-line-completed' : 'bg-gray-300'} mx-2 line`}></div>
        </li>
        <li className="flex w-full items-center">
          <div className={`step ${currentStep >= 2 ? 'active-step' : 'inactive-step'} ${currentStep > 2 ? 'completed-step' : ''} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>2</p>
          </div>
          <div className={`flex-grow h-1 transition-all duration-500 ${currentStep > 2 ? 'step-line-completed' : 'bg-gray-300'} mx-2 line`}></div>
        </li>
        <li className="flex items-center w-full">
          <div className={`step ${currentStep >= 3 ? 'active-step' : 'inactive-step'} ${currentStep > 3 ? 'completed-step' : ''} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>3</p>
          </div>
          <div className={`flex-grow h-1 transition-all duration-500 ${currentStep > 3 ? 'step-line-completed' : 'bg-gray-300'} mx-2 line`}></div>
        </li>
        <li className="flex items-center w-full">
          <div className={`step ${currentStep >= 4 ? 'active-step' : 'inactive-step'} ${currentStep > 4 ? 'completed-step' : ''} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>4</p>
          </div>
          <div className={`flex-grow h-1 transition-all duration-500 ${currentStep > 4 ? 'step-line-completed' : 'bg-gray-300'} mx-2 line`}></div>
        </li>
        <li className="flex items-center w-full">
          <div className={`step ${currentStep >= 5 ? 'active-step' : 'inactive-step'} ${currentStep > 5 ? 'completed-step' : ''} flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>5</p>
          </div>
          <div className={`flex-grow h-1 transition-all duration-500 ${currentStep > 5 ? 'step-line-completed' : 'bg-gray-300'} mx-2 line`}></div>
        </li>
        <li className="flex items-center w-full">
          <div className={`step ${currentStep >= 6 ? 'active-step' : 'inactive-step'} ${currentStep > 6 ? 'completed-step' : ''}flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0`}>
            <p>6</p>
          </div>
        </li>
      </ol>

      {currentStep === 1 && (
        <>
          <h2 className="text-white pb-4">What type of media are you capturing</h2>
          <p className="pt-4 pb-8">Select what type of media you will be capturing. Surfers will be able to select from the options you have chosen when booking out. You will only be able to book one type of media at a time.</p>
          <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1 gap-4">
            <MediaTypeBlock 
              className={`${selectedMediaTypes.photography ? 'selected-media' : ''}`}
              onClick={() => handleClick('photography')} 
            >
              <h5>Photography</h5>
            </MediaTypeBlock>
            <MediaTypeBlock 
              className={`${selectedMediaTypes.videography ? 'selected-media' : ''}`}
              onClick={() => handleClick('videography')}
            >
              <h5>Videography</h5>
            </MediaTypeBlock>
            <MediaTypeBlock 
              className={`${selectedMediaTypes.drone ? 'selected-media' : ''}`}
              onClick={() => handleClick('drone')}
            >
              <h5>Drone</h5>
            </MediaTypeBlock>
          </div>
        </>
      )}

      {currentStep === 2 && (
        <>   
          <h2 className="text-white pb-8">What is your hourly rate?</h2>
          <p>This price should include everything from your travel to the surf spot, equipment cost, editing, post processing etc. This houlry rate should reflect your final price per hour.</p>
          <h4 className="mt-8 mb-2">Hourly Rate</h4>
          <input
            onChange={handleHourlyRateChange}
            type="number"
            value={hourlyRate}
            placeholder="Hourly Rate (USD)"
            name="rate"
            className="px-3 py-2"
            min="0" // Disallow negative numbers
          />
        </>
      )}

      {currentStep === 3 && (
        <>
          <h2 className="text-white pb-8">Select surf spots you are willing to shoot.</h2>
          <p className="pb-8">These are the spots your listing will show up on when surfers are looking to book. Please keep in mind that travel to and from the location should be reflected in your hourly rate. Only select spots you are willing to travel to.</p>
          <SpotSelectOnboarding
            className='spot-select-custom'
            setShowModal={true}
            noSubmit={true}
            selectedContinent={selectedContinent}
            setSelectedContinent={setSelectedContinent}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            selectedCounty={selectedCounty}
            setSelectedCounty={setSelectedCounty}
            selectedSurfSpots={selectedSurfSpots}
            setSelectedSurfSpots={setSelectedSurfSpots}
          />
        </>
      )}

      {currentStep === 4 && (
        <>
          <h2 className="text-white pb-8">Upload a couple examples of your work.</h2>
          <p className="pb-8">These are the photos / videos that users will see in your profile as an example of your work. This is your chance to show your style and what makes you the best person to book. You will be able to add, change or remove these at any time.</p>
          <FileUploader
            classes="file-uploader"
            name="file"
            types={fileTypes}
            multiple
            required
            handleChange={handleFileChange}
            children={
              <FileUploadWrapper>
                <div className="iconwrapper">
                  <IconUpload />
                </div>
                <div className="py-2 text-center">
                  <p>SELECT YOUR PHOTOS/VIDEOS TO UPLOAD</p>
                  <h4>JPG, PNG, MP4</h4>
                </div>
                {selectedFiles?.length > 0 && (
                  <h4 style={{color: '#F27A54', paddingTop: '6px'}}>{selectedFiles.length} Files Selected</h4>
                )}
                <div className="upload_btn">
                  <p>SELECT FILES</p>
                </div>
              </FileUploadWrapper>
            }
          />
          {selectedFiles?.length > 0 && (
            <div className="flex mt-4">
              {Array.from(selectedFiles).map((file, index) => {
                const fileURL = URL.createObjectURL(file);
                const isVideo = file.type.startsWith('video/');

                return (
                  <div key={index} className="upload-thumbnail">
                    {isVideo ? (
                      <video
                        className="thumbnail-video"
                        src={fileURL}
                        controls
                        width="200px" // Adjust width as needed
                        height="auto" // Adjust height as needed
                      />
                    ) : (
                      <img
                        className="thumbnail-img"
                        src={fileURL}
                        alt={`preview-${index}`}
                        width="100" // Adjust width as needed
                        height="auto" // Adjust height as needed
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}


        </>
      )}

      {currentStep === 5 && (
        <>
          <h2 className="text-white pb-8">Write a short 'about me'.</h2>
          <p className="pb-8">Write a short description about your style of photography / videography or drone footage, type of equipment you use, experience or anything else you want people to know before they book you.</p>
          <DescriptionTextArea 
            onChange={(e) => setFlmrDescription(e.target.value)} 
            placeholder="Write here..." 
            className="w-full px-3 py-2 border-divider rounded-sm focus:outline-none focus:ring mb-4 mt-2 bg-inputDark" 
          />
        </>
      )}

      {currentStep === 6 && (
        <>
          <h2 className="text-white pb-8">You're ready to book clients!</h2>
          <p>Your profile will only be listed under the selected surf spots you have chosen (you can add/edit/remove these any time). If you no longer want to recieve requests for reservations, please turn off your active status in your profile.</p>
        </>
      )}

      <div className="flex mt-8">
        <button
          type="button"
          onClick={handlePrevious}
          className="inline-flex items-center justify-center w-full px-4 py-2.5 mb-4 text-sm text-[#CBCFD4] bg-[#1C2127] rounded-lg shadow-sm transition-colors duration-300 hover:bg-[#1c2127c0] focus:ring-opacity-80 sm:w-auto sm:mb-0 mr-2 cursor-pointer"
          disabled={currentStep === 1}
        >
          Previous
        </button>

        { currentStep === 6 ? 
          <button
          type="button"
          onClick={handleSubmit}
          className="submit inline-flex items-center justify-center w-full px-4 py-2.5 mb-4 text-sm rounded-lg shadow-sm transition-colors duration-300 sm:w-auto sm:mb-0 mr-2 cursor-pointer"
          // disabled={currentStep === 6}
          >
            Submit
          </button>
          : 
          <button type="button" onClick={handleNext} className="inline-flex items-center justify-center w-full px-4 py-2.5 mb-4 text-sm text-[#CBCFD4] bg-[#1C2127] rounded-lg shadow-sm transition-colors duration-300 hover:bg-[#1c2127c0] sm:w-auto sm:mb-0" disabled={currentStep === 6}>Next</button>
        }
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`

  input {
    background-color: #3c444c;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    color: #CBCFD4;
  }

  .step-btn {
    /* border: 1px solid #CBCFD4; */
    background-color: #3c444c;
    color: #CBCFD4;
  }

  .submit {
    color: white;
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 100%);
  }

  .submit:hover {
    transition: .25s ease-in-out;
    opacity: .8;
  }

  .step-btn:hover {
    background-color: #353c43;
  }

  .line {
    border-radius: 4px;
  }
  
  .step {
    border: 2px solid gray;
    background-color: transparent;
  }

  .inactive-step {
    border: 2px solid gray;
  }

  .active-step {
    border: 3px solid #f27a54;
    color: #30363d;
  }

  .step-line-completed {
    background-color: #f27a54;
    color: #30363d;
  }

  .completed-step {
    background-color: #f27a54;
  }

  .completed-step p {
    color: #30363d;
    /* font-weight: bold; */
  }

`;

const MediaTypeBlock = styled.div`
  cursor: pointer;  
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: #3c444c;
  color: #cbcfD4; /* Corrected capitalization */
  padding: 4rem;
  position: relative; /* Ensure ::before pseudo-element is positioned relative to this */

  &.selected-media:before {
    border: 2px solid #f07957;
  }
  
  ::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color 0.1s ease;
  }

  :hover::before {
    border-color: #f07957;
  }

  h5 {
    font-size: 12px;
  }

`

const FileUploadWrapper = styled.div`
  display: flex;
  cursor: pointer;
  border: 2px solid #3c444c;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
  transition: .1s ease-in-out;

  :hover {
    background-color: #353c43;
  }

  svg path {
    fill: #CBCFD4;
  }

  h4 {
    color: #CBCFD4; 
  }
  .iconwrapper {
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #3c444c;
    background-color: #30363d;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload_btn {
    background-color: #30363d;
    border: 1px solid #3c444c;
    padding: .5rem 1rem;
    border-radius: 7px;
    margin-top: 1rem;
    p {
      color: #CBCFD4;
    }
  }
  @media screen and (max-width: 525px) { 
    p {
      font-size: 10px;
    }
  }
`

const ThumbnailImg = styled.img`
  height: 75px;
  margin-right: 10px;
`

const DescriptionTextArea = styled.textarea`
  display: flex;
  width: 100%;
  height: 175px;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 14px;
  outline: none;
  color: #CBCFD4;
  background-color: #3c444c;

  ::placeholder {
    color: #5d6165;
  }
`

export default Stepper;
