import * as React from 'react';
import styled from 'styled-components'
import IconPhotos from '../../Components/Icons/IconPhotos';

interface AlbumCardPhotoCountProps {
  mediaCount: number
}

function AlbumCardPhotoCount(props: AlbumCardPhotoCountProps) {
  return (
    <Wrapper>
      <IconPhotos />
      <p>{props.mediaCount}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: space-around;
  top: 7%;
  width: 75px;
  height: 35px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 186.42%);
  border-radius: 0px 20px 20px 0px;

  p {
    font-weight: 700;
    color: white;
  }
  
`

export default AlbumCardPhotoCount