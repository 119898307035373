import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { toast } from 'react-toastify';

interface ProfileData {
  flmr_media_type: string[];
  flmr_hourly_rate: number;
  flmr_locations: string[];
  flmr_available: boolean;
  flmr_about: string,
  is_flmr: boolean,
  onboarding_completed: boolean;
  profile_images: FileList | null;
}

export async function NewFlmrListing(userid: any, profileData: ProfileData) {
  try {
    const db = getFirestore();
    const storage = getStorage();
    const profileRef = doc(db, "users", userid);

    // Update the user's profile in Firestore
    await updateDoc(profileRef, {
      onboarding_completed: true,
      flmr_about: profileData.flmr_about,
      flmr_media_type: profileData.flmr_media_type,
      flmr_hourly_rate: profileData.flmr_hourly_rate,
      flmr_locations: arrayUnion(...profileData.flmr_locations),
      flmr_available: profileData.flmr_available,
      is_flmr: profileData.is_flmr
    });

    // Upload the user's selected images to Firebase Storage
    if (profileData.profile_images) {
      const uploadPromises = Array.from(profileData.profile_images).map(async (file, index) => {
        const storageRef = ref(storage, `user_images/${userid}/listing_banners/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      });

      const imageUrls = await Promise.all(uploadPromises);

      // Optionally, you can update the Firestore document with the image URLs
      await updateDoc(profileRef, {
        flmr_listing_banner_urls: arrayUnion(...imageUrls)
      });
    }

    toast.success("Listing Created!", {
      theme: "dark"
    });
  } catch (error) {
    console.error("Error updating listing:", error);
    toast.error("Failed to update listing", {
      theme: "dark"
    });
  }
}
