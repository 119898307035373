import { getFirestore, collection, doc, updateDoc, addDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

interface BookingData {
  booking_continent: string,
  booking_cost_net: number,
  booking_cost_total: number,
  booking_country: string,
  booking_fees: number,
  booking_hourly_rate: number,
  booking_length: number,
  booking_media_type: string,
  booking_start_date_time: any,
  booking_state: string,
  booking_status: string,
  booking_surf_spot: string,
  booking_timestamp: any,
  booking_user_id: string,
  booking_user_username: string,
  flmr_id: string,
  flmr_username: string
}

export async function NewBooking(bookingData: BookingData) {
  try {
    const db = getFirestore();
    const bookingCollectionRef = collection(db, "bookings");
    const docRef = await addDoc(bookingCollectionRef, {
      booking_continent: bookingData.booking_continent,
      booking_cost_net: bookingData.booking_cost_net,
      booking_cost_total: bookingData.booking_cost_total,
      booking_country: bookingData.booking_country,
      booking_fees: bookingData.booking_fees,
      booking_hourly_rate: bookingData.booking_hourly_rate,
      booking_length: bookingData.booking_length,
      booking_media_type: bookingData.booking_media_type,
      booking_start_date_time: bookingData.booking_start_date_time,
      booking_state: bookingData.booking_state,
      booking_status: bookingData.booking_status,
      booking_surf_spot: bookingData.booking_surf_spot,
      booking_timestamp: bookingData.booking_timestamp,
      booking_user_id: bookingData.booking_user_id,
      booking_user_username: bookingData.booking_user_username,
      flmr_id: bookingData.flmr_id,
      flmr_username: bookingData.flmr_username
    });

    // toast.success("Request Sent!", {
    //   theme: "dark"
    // });

    // Return the document ID
    return docRef.id;
    
  } catch (error) {
    console.error("Error updating listing:", error);
    toast.error("Failed to update listing", {
      theme: "dark"
    });
    return null; // Return null in case of error
  }
}

