import React from 'react';
import styled from 'styled-components';
import IconView from '../../Components/Icons/IconView';
import IconBuy from '../../Components/Icons/IconBuy';
// import IconLike from '../../Components/Icons/IconLike';
import PhotoCardCost from '../PhotoCardCost/PhotoCardCost';
import IconDownload from '../Icons/IconDownload';
import { toast } from 'react-toastify';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from '../../Context/UserAuthContext';
import { AddToCart } from '../../Services/api/get/AddToCart/AddToCart';

interface PhotoCardProps {
  albumSpot: string,
  albumThumbnail?: string,
  albumName: string,
  photographerName: string,
  photographDate: string,
  photographUrl: string,
  fullSizeUrl: string,
  mediaPrice: string,
  imageName: any,
  owner: string
}

function PhotoCard(props: PhotoCardProps) {
  
  const [isVideo, setIsVideo] = React.useState(false);
  const storage = getStorage();
  let { user } = useUserAuth();
 
  // This is required for downloading images
  function handleClick() {
    getDownloadURL(ref(storage, `${props.fullSizeUrl}`))
      .then((url) => {
        // Download the file as a blob
        return fetch(url).then(res => res.blob());
      })
      .then((blob) => {
        // Create a temporary link element and set its href to the blob URL
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        const fileExtension = blob.type.split('/')[1];
        link.setAttribute('download', `${props.imageName}_${props.photographerName}.${fileExtension}`);
        document.body.appendChild(link);
  
        // Trigger the click event on the link element
        link.click();
  
        // Remove the link element from the DOM
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Handle Add to Cart click - write to cloud firestore
  async function handleAddToCartClick(event: any) {
    if (!user) {
      toast.error('Please log in to purchase...', {
        theme: 'dark',
      });
      return;
    }
    await AddToCart(user, props);
  }

  // Get metadata
  // albums/owner/spot/albumname/imgname
  // console.log("albums/" + `${props.owner}/` + `${props.albumSpot}/`)
  
  // Handle any errors from image render
  function handleError() {
    setIsVideo(true);
  }

  return (
    <PhotoCardWrapper className='shadow-lg'>
      <BannerImgWrapper>
        <PhotoCardCost photoCost={props.mediaPrice}/>
        {isVideo ? (
        <video
          controls
          muted
          className="object-cover"
          src={props.photographUrl}
          onError={handleError}
          style={{ width: '100%' }}
        />
        ) : (
          <a href={props.fullSizeUrl} target='_blank'>
            <BannerImg
            onError={handleError}
            src={props.photographUrl}
          />
          </a>
        )}
      </BannerImgWrapper>
      <ControlWrapper>
        <Info>
          <p><b>{props.photographerName}</b></p>
          <p>{props.photographDate}</p>
        </Info>
        <Controls>
          <ControlButtonContainer>
            <a href={props.fullSizeUrl} target="_blank">
              <IconView />
            </a>
          </ControlButtonContainer>
          { parseInt(props.mediaPrice) > 0 
            ? <ControlButtonContainer onClick={handleAddToCartClick}><IconBuy /></ControlButtonContainer>
            : <ControlButtonContainer onClick={handleClick}><IconDownload /></ControlButtonContainer>  
          }
        </Controls>
      </ControlWrapper>
    </PhotoCardWrapper>
  )
}

const Info = styled.div`
  p {
    line-height: 20px;
  }
`

const BannerImg = styled.img`
  object-fit: cover;
  height: 175px;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`

const BannerImgWrapper = styled.div`
  cursor: pointer;
  position: relative;

  img {
    border-radius: 3px;
    max-height: 275px;
    min-height: 275px;
    width: 100%;
    object-fit: cover;
  }
`

const ControlButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
`

const PhotoCardWrapper = styled.div`
  border: 1px solid #30363d;

  video {
    max-height: 176px;
  }

  h4 {
    color: #CBCFD4;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  background-color: #3d444c;
`

export default PhotoCard