import React, { useState } from 'react';

function IconBuy() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = hovered ? '#F27A54' : '#31373E';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
        <path d="M23.1334 15.6H9.69754L9.99752 17.1H22.3C23.0059 17.1 23.5291 17.7703 23.3726 18.4743L23.1198 19.6122C23.9765 20.0375 24.5672 20.9357 24.5672 21.975C24.5672 23.4376 23.3975 24.6208 21.9627 24.5997C20.5958 24.5796 19.4717 23.4451 19.4348 22.0475C19.4146 21.284 19.7137 20.5921 20.2049 20.0999H10.5961C11.0717 20.5765 11.3672 21.2402 11.3672 21.975C11.3672 23.4662 10.1513 24.667 8.67811 24.5971C7.37003 24.535 6.30619 23.454 6.23744 22.1166C6.18437 21.0837 6.71576 20.1734 7.52311 19.6985L4.30346 3.59997H1.10049C0.492967 3.59997 0.000488281 3.0963 0.000488281 2.47497V1.72498C0.000488281 1.10365 0.492967 0.599976 1.10049 0.599976H5.79973C6.32228 0.599976 6.77269 0.97596 6.87741 1.49951L7.29752 3.59997H25.3C26.0059 3.59997 26.5291 4.27033 26.3727 4.9743L24.206 14.7243C24.0922 15.2365 23.647 15.6 23.1334 15.6ZM18.7005 8.47497H16.5005V6.59997C16.5005 6.18574 16.1722 5.84997 15.7672 5.84997H15.0338C14.6288 5.84997 14.3005 6.18574 14.3005 6.59997V8.47497H12.1005C11.6955 8.47497 11.3672 8.81074 11.3672 9.22497V9.97497C11.3672 10.3892 11.6955 10.725 12.1005 10.725H14.3005V12.6C14.3005 13.0142 14.6288 13.35 15.0338 13.35H15.7672C16.1722 13.35 16.5005 13.0142 16.5005 12.6V10.725H18.7005C19.1055 10.725 19.4338 10.3892 19.4338 9.97497V9.22497C19.4338 8.81074 19.1055 8.47497 18.7005 8.47497Z" fill={fill} style={svgStyle} />
      </svg>
    </div>
  );
}

export default IconBuy;