import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from React Router for navigation

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-[#30363d] ">
      <div className="flex flex-col lg:h-[calc(100vh-80px)]">
        <div 
          className="flex-grow flex items-center justify-center px-6"
          style={{ minHeight: 'calc(100vh - 80px)' }} // Adjust the min-height to account for the fixed navbar
        >
          <div>
            <p className="text-sm font-medium text-[#f27a54]">404 ERROR</p>
            <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
              We can’t find that page
            </h1>
            <p className="mt-4 text-gray-500 dark:text-gray-400">
              Where were you trying to go? Some secret spot? You got the wrong place - that page doesn't exist.
            </p>

            <div className="flex items-center mt-6 gap-x-3">
              <button 
                onClick={() => navigate(-1)}
                className="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 border border-gray-300 rounded-lg gap-x-2 sm:w-auto bg-transparent dark:text-gray-200 hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:rotate-180">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>
                <span>Go back</span>
              </button>

              <Link 
                to="/" 
                className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-[#3c444c] rounded-lg shrink-0 sm:w-auto hover:bg-[#272e36]"
              >
                Take me home
              </Link>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
