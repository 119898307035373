import React, { useState } from 'react'
import styled from 'styled-components'
import Stepper from '../../Components/Stepper/Stepper';

function Onboarding() {

  const [currentStep, setCurrentStep] = useState(1);
  
  return (
    
    <Wrapper className="mt-4">      
      <Stepper />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  font-family: 'Montserrat';
  /* background-color: #3c444c; */
  border-radius: 4px;
  padding: 25px 25px;
  max-width: 960px;
  margin: 1rem auto;
`

export default Onboarding