import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { toast } from 'react-toastify';

interface BookingHistory {
  document_id: string;
  [key: string]: any;
}

export async function BookingHistory(user: any): Promise<BookingHistory[]> {
  const db = getFirestore();

  try {
    const bookingReqsCollectionRef = collection(db, "bookings");

    // Query for documents where booking_status is one of the specified values
    const q = query(
      bookingReqsCollectionRef,
      where("booking_user_id", "==", user.uid),
      where("booking_status", "in", [
        "inprogress_canceled_by_flmr",
        "canceled_by_flmr",
        "canceled_by_surfer",
        "inprogress_canceled_by_surfer",
        "completed"
      ])
    );

    const querySnapshot = await getDocs(q);

    // Map the documents to include their IDs
    const bookingReqs = querySnapshot.docs.map((doc) => ({
      document_id: doc.id,
      ...doc.data(),
    }));

    return bookingReqs;
  } catch (error) {
    console.error("Error fetching booking requests:", error);
    toast.error("Failed to fetch booking requests", {
      theme: "dark",
    });
    return [];
  }
}
