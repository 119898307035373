import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getDatabase, ref, set } from "firebase/database";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { auth } from "../../Services/firebase/FirebaseConfig";
import { toast } from 'react-toastify';
import { AddUserAvatar } from '../../Services/api/get/AddUserAvatar/AddUserAvatar';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  // Grab the FireBase database
  const database = getDatabase();
  const db = getFirestore();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState('');
  let navigate = useNavigate();

  const currentAuth = auth;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    createUserWithEmailAndPassword(currentAuth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // Write user to firestore
        const firestoreUserRef = doc(db, "users", user.uid);
        let newUserData = {
          avatar_ref: '',
          current_continent: 'North America',
          current_country: 'United States',
          current_county: '',
          current_location: 'Rhode Island',
          current_spot: '2nd Beach (Sachuest Beach)',
          current_state: 'Rhode Island',
          email: email,
          user_id: user.uid,
          username: username,
          is_flmr: false,
          onboarding_completed: false
        }
        setDoc(firestoreUserRef, newUserData);
        // Add users avatar to storage
        AddUserAvatar(user.uid);
        // End Write to firestore
        // Push an instance of the user's profile into the realtime db
        set(ref(database, 'users/' + user.uid), {
          userId: user.uid,
          email: email,
          userName: username,
          currentContinent: 'North America',
          currentCountry: 'United States',
          currentState: 'Rhode Island',
          currentLocation: 'Rhode Island',
          currentSpot: '2nd Beach (Sachuest Beach)',
          hasAlbum: false,
          defaultAlbumBanner: ''
        })
        .then(() => {
          navigate('/photographers/North America/United States/Rhode Island/2nd Beach (Sachuest Beach)');
          toast.success("Account Created, Welcome to FLMR", {
            theme: "dark"
          });
        })
        .catch((error) => {
          // Handle any error during the database update
          console.error(error);
          toast.error("Failed to update user profile", {
            theme: "dark"
          });
        });
      })
      .catch((error) => {
        // Handle any error during user creation
        console.error(error);
        toast.error(error.message, {
          theme: "dark"
        });
      });
  };
  
  // function handleAvatar() {
  //   AddUserAvatar('1324asd123123')
  // }

  return (
    <>
    <SignUpWrapper>  
      <div className="container max-w-md p-2">
        <div className="text-center my-6">
          <h1 className="text-3xl font-semibold text-white pb-4">FLMR</h1>
          <p>Signup for an account to start booking photographers for your next session or to get listed to start taking bookings.</p>
        </div>
        <div className="m-6">
        <form onSubmit={handleSubmit} className="mb-4">
            <div className="">
              <label className="block mb-1 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
              <input 
                placeholder="Email address" 
                name="email"
                onChange={ (e) => setEmail(e.target.value) } 
                type="email" id="email" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mt-2 mb-4">
              <div className="flex justify-between mb-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">Username</label>
              </div>
              <input 
                placeholder="Enter a username" 
                name="username"
                onChange={ (e) => setUsername(e.target.value) } 
                type="username" id="username" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mt-2 mb-4">
              <div className="flex justify-between mb-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">Password</label>
              </div>
              <input 
                placeholder="Password" 
                type="password" 
                name="password"
                onChange={ (e) => setPassword(e.target.value) }
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mb-4">
              <button className="btn-signin" type="submit">SIGN UP</button>
              
            </div>
            <p className="text-center text-sm">
              Already have an accout? <Link to="/login"><span className="font-semibold">Login here</span></Link>
            </p>
            {/* <button onClick={handleAvatar}>CLICK</button> */}
          </form>
        </div>
      </div>
    </SignUpWrapper>
    </>
  );
};


const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;

  .container {
    background-color: #3C444C;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 4%), 0 2px 2px hsl(0deg 0% 0% / 4%), 0 4px 4px hsl(0deg 0% 0% / 4%), 0 8px 8px hsl(0deg 0% 0% / 4%), 0 16px 16px hsl(0deg 0% 0% / 4%);
  }
  
  .btn-signin {
    width: 200px;
    height: 40px;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border-radius: 7px;
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 186.42%);
    letter-spacing: 1px;
  }
`

export default Register
