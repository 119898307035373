import React, { useState } from 'react';
import styled from 'styled-components';
import AlbumCardPhotoCount from '../AlbumCardPhotoCount/AlbumCardPhotoCount';

interface MyAlbumCardProps {
  album_continent: string,
  album_country: string,
  album_county: string,
  album_default_banner: string,
  album_description: string,
  album_media_count: number,
  album_media_price: number | string,
  album_name: string,
  album_owner_id: string,
  album_owner_username: string,
  album_slug: string,
  album_spot: string,
  album_state: string,
  album_upload_date: string,
  session_date: string,
  session_time: string,
  onDelete?: any
}

function MyAlbumsCard(props: MyAlbumCardProps) {

  const [isVideo, setIsVideo] = useState(false);

  function handleError() {
    setIsVideo(true);
  }

  return (
    <Wrapper>
      <AlbumCardPhotoCount mediaCount={props.album_media_count}/>
      {/* TODO: Get the users first posted image and use as the banner */}
      {isVideo ? (
        <video
          controls
          muted
          className="object-cover"
          src={props.album_default_banner}
          onError={handleError}
          style={{ width: '100%' }}
        />
        ) : (
          <BannerImg
            onError={handleError}
            src={props.album_default_banner}
          />
        )}
      <ContentWrapper>
        {/* <UserImg className="mb-3 w-12 h-12 rounded-full shadow-lg" src={props.avatar} alt=""/> */}
        {/* TODO: This needs to be the uploaded users username, not email */}
        <h4 style={{padding: "10px 0px"}}>{props.album_owner_username}</h4>
        <h5 className="mb-1 text-xl font-medium text-white">{props.album_name}</h5>
        <h4 style={{padding: "10px 0px"}}>{props.album_spot}</h4>
        <p className="pb-2"><b>Session Date: </b>{props.session_date}</p>
        <p>{props.album_description}</p>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #3C444C;

  video {
    max-height: 176px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  h4 {
    color: #F27A54;
    font-size: 12px;
    text-transform: uppercase;
  }
`

const UserImg = styled.img`
  position: absolute;
  background-color: #3c444c;
  padding: 2px;
  right: 45px;
  top: 151px;
`

const BannerImg = styled.img`
  object-fit: cover;
  height: 175px;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`

export default MyAlbumsCard