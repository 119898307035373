import React from 'react'
import styled from 'styled-components';
import IconCheckCircle from '../../Components/Icons/IconCheckCircle';
import { Link, useSearchParams } from 'react-router-dom';

function BookingSuccess() {

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  return (
    <Wrapper>
      <InnerWrapper>
      <IntroTextWrapper>
        <div className="flex justify-center mb-12 mt-6">
          <IconCheckCircle />
        </div>
        <h2 className="text-white mb-4">Booking Requested!</h2>
        <div className="my-4">
          <p className="text-white">Your booking request was successful! You will only be charged once host has accepted your reservation.</p>
          <p className="break-all"><b>Booking Number: </b>{sessionId}</p>
        </div>
        <Link to={'/bookings'}><button className="view-booking mt-4">VIEW BOOKING</button></Link>
      </IntroTextWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  align-items: center;
  margin-top: 1rem;
  border-radius: 3px;

  .view-booking {
    padding: .5rem 1.5rem;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    /* width: 35%; */
    color: #30363d;
    background-color: #f27a54;
    transition: background-color 0.1s ease-in-out;
  }

  .view-booking:hover {
    background-color: #f27a54ad;
  }
`

const IntroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  

  svg {
    width: 75px;
    height: 75px;
  }

`
const InnerWrapper = styled.div`
  max-width: 960px;
  background-color: #3C444C;
  padding: 2rem;
  margin: 1rem;
  
`

const FancyText = styled.h2`
  font-family: 'Montserrat', sans-serif;
  padding: 1rem 0rem;
  font-size: 50px;
  line-height: 70px;
  background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default BookingSuccess