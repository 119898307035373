import React from 'react';
import styled from 'styled-components';

function SecondaryButton({children, className=''}) {
  return (
    <ButtonWrapper className={className}>
      {children}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button`
 
    width: 100px;
    height: 30px; 
    border: 1px solid white;
    font-size: 10px;
    font-weight: 700;
    color: white;
    border-radius: 7px;
    letter-spacing: 1px;
    background-image: -webkit-linear-gradient(30deg, #fff 50%, transparent 50%);
    background-image: linear-gradient(30deg, #fff 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 100%;
    -webkit-transition: background 250ms ease-in-out;
    transition: background 250ms ease-in-out;

  :hover {
    background-position: 0%;
    color: #30363D;
  }
`

export default SecondaryButton