import React from 'react';
import { useUserAuth } from '../../Context/UserAuthContext';
import 'firebase/database';
import styled from 'styled-components';
import Modal from '../SpotSelectModal/SpotSelectModal';
import { Link, useParams } from 'react-router-dom';
import IconPlus from '../Icons/IconPlus';
import CreateAlbumBtn from '../Buttons/CreateAlbumBtn';

// import PickDate from '../PickDate/PickDate';

function DashboardInfoBar({surfspot}) {

  const { user } = useUserAuth();
  const { continent, country, state, county } = useParams();

  return (
    <InfoBar>
      <div className="grid sm:grid-cols-1 lg:grid-cols-5 md:grid-cols-5 w-full">
        <div className="firstRow col-span-3">
          <h1 style={{paddingBottom: '5px'}}>{surfspot}</h1>
          {/* TODO: Replace this with breadcrumb component */}
          <p>{continent} / {country} / {state} / {county ? `${county} /` : ''} <b>{surfspot}</b></p>
        </div>
        <div className="py-4 secondRow col-span-2">
          <div>
            <Modal/>
          </div>
        </div>
      </div>
    </InfoBar>
  )
}

const InfoBar = styled.div`
  border-radius: 4px;

  p {
    line-height: 20px;
  }

  .firstRow {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;
  }

  .secondRow {
    display: flex;
    align-items: right;
    justify-content: flex-end;
    align-items: center;
  }

  @media screen and (max-width: 765px) {
    gap: 0;
    .secondRow {
      justify-content: flex-start;
      /* padding-top: 0px; */
    }
  }
`

const BtnWrapper = styled.div`
  /* width: 135px; */
`

export default DashboardInfoBar