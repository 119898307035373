import React from 'react';
import { Navigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useUserAuth } from '../../Context/UserAuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUserAuth();
  
  if (loading) {
    // You can render a loading spinner or message here while waiting for authentication check
    // TODO: Render skeleton or loading state here
    return <div className="flex justify-center align-middle">
              <LoadingSpinner size='40' color="#F27A54" />
           </div>
  }

  if (!user) {
    return <Navigate to="/"/>;
  }

  return children;
};

export default ProtectedRoute;
