import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { toast } from 'react-toastify';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';

function PasswordReset() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  let navigate = useNavigate();
  const location = useLocation();

  const handlePasswordReset =  async (e: any) => {
    e.preventDefault(); 
    if (password === passwordConfirm) {
      try {
        const oobCode = new URLSearchParams(location.search).get('oobCode');
        if (oobCode) {
          // Use Firebase Auth API to reset the password
          const auth = getAuth();
          await verifyPasswordResetCode(auth, oobCode);
          await confirmPasswordReset(auth, oobCode, password);
          toast.success('Password reset successfully!', {
            theme: 'dark',
          });
          navigate('/login'); // Redirect the user to the login page
        } else {
          toast.error('Invalid reset password link', {
            theme: 'dark',
          });
        }
      } catch (error) {
        toast.error('Password reset failed', {
          theme: 'dark',
        });
      }
    } else {
      toast.warning("Passwords do not match", {
        theme: "dark"
      });
    }
  }

  const handleSubmit = () => {
    toast.warning("Form submitted", {
      theme: "dark"
    });
  }

  return (
    <>
    <LoginWrapper className="">  
      <div className="container max-w-md p-2">
        <div className="my-6 ">
          <h2 className="text-3xl font-semibold text-white pb-4 text-center">Create New Password</h2>
          <p className="mx-6">Enter your new password. Make it a strong one, make sure it's rad too.</p>
        </div>
        <div className="m-6">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="mt-2 mb-4">
              <div className="flex justify-between items-center mb-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">New Password</label>
              </div>
              <input  
                type="password" 
                onChange={ (e) => setPassword(e.target.value) }
                id="password" placeholder="New password" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mt-2 mb-4">
              <div className="flex justify-between items-center mb-1">
                <label className="text-sm text-gray-600 dark:text-gray-400">Confirm Password</label>
              </div>
              <input  
                type="password" 
                onChange={ (e) => setPasswordConfirm(e.target.value) }
                id="passwordConfirm" placeholder="Confirm Password" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-sm focus:outline-none focus:ring text-slate-500" />
            </div>
            <div className="mb-4">
              <PrimaryButton onClick={handlePasswordReset} className="pb-4">SUBMIT</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </LoginWrapper>
    </>
  )
}

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;

  h2 {
    background: -webkit-linear-gradient(60deg,#F27A54,#A154F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .container {
    background-color: #3C444C;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 4%), 0 2px 2px hsl(0deg 0% 0% / 4%), 0 4px 4px hsl(0deg 0% 0% / 4%), 0 8px 8px hsl(0deg 0% 0% / 4%), 0 16px 16px hsl(0deg 0% 0% / 4%);
  }
    // -tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) rgb(234 118 103);
  .btn-signin {
    width: 200px;
    height: 40px;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border-radius: 7px;
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 186.42%);
    letter-spacing: 1px;
  }
`

export default PasswordReset