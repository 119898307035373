import React, { useState, useEffect } from 'react';
import SurfSpots from '../../Data/SurfSpots_New.json';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const SpotSelectOnboarding = ({
  setShowModal,
  noSubmit,
  className,
  selectedContinent,
  setSelectedContinent,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
  selectedCounty,
  setSelectedCounty,
  selectedSurfSpots,
  setSelectedSurfSpots,
}) => {
  const [countries, setCountries] = useState([]);
  const [counties, setCounties] = useState([]);
  const [states, setStates] = useState([]);
  const [surfSpots, setSurfSpots] = useState([]);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const uniqueContinents = [...new Set(SurfSpots.map((spot) => spot.continent))];

  useEffect(() => {
    if (selectedContinent) {
      const selectedCountries = SurfSpots.find(
        (spot) => spot.continent === selectedContinent
      )?.countries ?? [];
      setCountries(selectedCountries);
      setStates([]);
    }
  }, [selectedContinent]);

  useEffect(() => {
    if (selectedCountry) {
      const countryData = SurfSpots.find(
        (spot) => spot.continent === selectedContinent
      )?.countries.find((country) => country.name === selectedCountry);

      const selectedStates = countryData?.states.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
      setStates(selectedStates);
    }
  }, [selectedCountry, selectedContinent]);

  useEffect(() => {
    if (selectedState) {
      const countryData = SurfSpots.find(
        (spot) => spot.continent === selectedContinent
      )?.countries.find((country) => country.name === selectedCountry);
  
      const stateData = countryData?.states.find((state) => state.name === selectedState);
      const selectedCounties = stateData?.counties ?? [];
      setCounties(selectedCounties);
  
      const selectedSurfSpots = stateData?.surfSpots ?? [];
      setSurfSpots(selectedSurfSpots);
  
      const options = selectedSurfSpots.map((spot) => ({
        label: spot,
        value: spot,
      }));
      setOptions(options);
    }
  }, [selectedState, selectedContinent, selectedCountry]);

  useEffect(() => {
    if (selectedCounty) {
      const countryData = SurfSpots.find(
        (spot) => spot.continent === selectedContinent
      )?.countries.find((country) => country.name === selectedCountry);
  
      const stateData = countryData?.states.find((state) => state.name === selectedState);
      const countyData = stateData?.counties.find((county) => county.name === selectedCounty);
  
      const selectedSurfSpots = countyData?.surfSpots ?? [];
      setSurfSpots(selectedSurfSpots);
  
      const options = selectedSurfSpots.map((spot) => ({
        label: spot,
        value: spot,
      }));
      setOptions(options);
    }
  }, [selectedCounty, selectedState, selectedContinent, selectedCountry]);

  const handleSubmit = () => {
    const surfSpotValues = selectedSurfSpots.map((spot) => spot.value).join(',');
    const onNewAlbumUrl = `/newalbum/${selectedContinent}/${selectedCountry}/${selectedState}${selectedCounty ? `/${selectedCounty}` : ''}/${surfSpotValues}`;
    const onFindPhotosPageUrl = `/${selectedContinent}/${selectedCountry}/${selectedState}${selectedCounty ? `/${selectedCounty}` : ''}/${surfSpotValues}`;

    if (window.location.pathname.includes('/newalbum')) {
      navigate(onNewAlbumUrl);
    } else {
      navigate(onFindPhotosPageUrl);
    }

    setShowModal(false);
  };

  const handleContinentChange = (event) => {
    setSelectedContinent(event.target.value);
    setSelectedCountry('');
    setSelectedState('');
    setSelectedCounty('');
  };

  const handleCountryChange = (event) => {
    const newSelectedCountry = event.target.value;
    
    // Check if selectedCountry is changing
    if (newSelectedCountry !== selectedCountry) {
      setSelectedCountry(newSelectedCountry);
      setSelectedState('');
      setSelectedCounty('');
      setSelectedSurfSpots([]);
      setSurfSpots([]);
    }
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    
    // Check if selectedState is changing
    if (newState !== selectedState) {
      setSelectedState(newState);
      setSelectedCounty('');
      setSelectedSurfSpots([]);
      setSurfSpots([]);
    }
  };

  const handleCountyChange = (event) => {
    const newCounty = event.target.value;
    
    // Check if selectedCounty is changing
    if (newCounty !== selectedCounty) {
      setSelectedCounty(newCounty);
      setSelectedSurfSpots([]);
      setSurfSpots([]);
    }
  };
  const handleSurfSpotChange = (selectedOptions) => {
    setSelectedSurfSpots(selectedOptions); // Update parent state
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#3c444c',
      border: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#2e343a',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#30363d',
      marginRight: '6px',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#CBCFD4',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#F27A54',
        color: '#fff',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#F27A54' : '#2e343a',
      color: '#CBCFD4',
      '&:hover': {
        backgroundColor: '#F27A54',
        color: '#FFF',
      },
    }),
  };

  return (
    <Wrapper className={className}>
      <label>
        <h4>Continent</h4>
        <select value={selectedContinent} onChange={handleContinentChange}>
          <option value=""></option>
          {uniqueContinents.map((continent) => (
            <option key={continent} value={continent}>
              {continent}
            </option>
          ))}
        </select>
      </label>
      {countries.length > 0 && selectedContinent && (
        <label>
          <h4>Country</h4>
          <select value={selectedCountry} onChange={handleCountryChange}>
            <option value=""></option>
            {countries.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </label>
      )}
      {states.length > 0 && selectedCountry && (
        <label>
          <h4>State</h4>
          <select value={selectedState} onChange={handleStateChange}>
            <option value=""></option>
            {states.map((state) => (
              <option key={state.name} value={state.name}>
                {state.name}
              </option>
            ))}
          </select>
        </label>
      )}
      {counties.length > 0 && selectedState && (
        <label>
          <h4>County</h4>
          <select value={selectedCounty} onChange={handleCountyChange}>
            <option value=""></option>
            {counties.map((county) => (
              <option key={county.name} value={county.name}>
                {county.name}
              </option>
            ))}
          </select>
        </label>
      )}
      {surfSpots.length > 0 && selectedState && (
        <label>
          <h4>Surf Spot</h4>
          <Select
            closeMenuOnSelect={false}
            isMulti
            options={options}
            value={selectedSurfSpots}
            onChange={handleSurfSpotChange}
            styles={customStyles}
          />
        </label>
      )}
      {noSubmit ? null : (
        <SubmitSpotBtn
          title="Select a spot to submit"
          disabled={selectedSurfSpots.length === 0}
          onClick={handleSubmit}
        >
          SUBMIT
        </SubmitSpotBtn>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #cbcfd4;

  .spot-select-custom select {
    background-color: #3c444c;
    border: none;
    margin-bottom: .5rem;
  }

  .control {
    background-color: red;
  }

  label {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0rem;

    h4 {
      padding-bottom: 7px;
      font-size: 12px;
    }
  }

  select {
    background-color: #3c444c;
    /* font-size: 12px; */
    color: #cbcfd4;
    padding: 0.5rem;
    border-radius: 3px;
  }
`;

const SubmitSpotBtn = styled.button`
  font-weight: 700;
  margin: 1.5rem 0rem;
  width: 125px;
  height: 35px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  border-radius: 7px;
  background: linear-gradient(90deg, #f27a54 0%, #a154f2 100%);

  :disabled {
    background: gray;
  }
`;

export default SpotSelectOnboarding;
