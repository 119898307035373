import './App.css';
import React, {Suspense} from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard/Dashboard';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import AdminRoute from './Components/ProtectedRoute/AdminRoute';
import { UserAuthContextProvider } from './Context/UserAuthContext';
import AuthedUserProfile from './Components/AuthedUserProfile/AuthedUserProfile';
import Profile from './Components/Profile/Profile';
import NavBar from './Components/Navbar/NavBar';
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';
import Album from './Pages/Album/Album.tsx';
import NewAlbum from './Pages/NewAlbum/NewAlbum';
import UsersAlbums from './Pages/UsersAlbums/UsersAlbums';
import SignUpValidate from './Pages/SignUpValidate/SignUpValidate';
import SignUpValidateSuccess from './Pages/SignUpValidateSuccess/SignUpValidateSuccess';
import PasswordReset from './Pages/PasswordReset/PasswordReset';
import Dashboard_Photographers from './Pages/Dashboard_Photographers/Dashboard_Photographers';
import FilmerProfile from './Pages/FilmerProfile/FilmerProfile';
import Bookings from './Pages/Bookings/Bookings';
import Cart from './Pages/Cart/Cart';
import Faqs from './Pages/FAQs/Faqs';
import Onboarding from './Pages/Onboarding/Onboarding';
import BookingSuccess from './Pages/BookingSuccess/BookingSuccess';
import Tos from './Pages/ToS/ToS';
import OnboardingStart from './Pages/Onboarding/OnboardingStart';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import NotFound from './Pages/NotFound/NotFound';
import Admin from './Pages/Admin/Admin';

function App() {

  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };
  const stripePromise = loadStripe('pk_test_51NQcLsJOAjrijV2iss5Xj8LiWDsaqS04D5AL9YIJPlhW6jkaJzrNGh7RZeRWhhsU4S0qNgHHNjy0nl9tFNdzrzLq00xE3NE6FA');

  return (
    <div className="App">
      <Elements stripe={stripePromise} options={options}>
      <UserAuthContextProvider>
        <NavBar />
        <Suspense>
          <Routes>
          {/* 404 Catch All */}
          <Route path="*" element={<NotFound />} />
          {/* Admin Page */}
          <Route path="/admin" element={
            <AdminRoute>
              <Admin/>
            </AdminRoute>
          } />
          <Route path="/" element={<Home/>} />
          <Route path="/newalbum/:continent/:country/:state/:county?/:surfspot" element={
            <ProtectedRoute>
              <NewAlbum/>
            </ProtectedRoute>
          } />
          <Route path="/newalbum" element={
            <ProtectedRoute>
              <NewAlbum/>
            </ProtectedRoute>
          } />
          <Route path="/booking-success" element={
            <ProtectedRoute>
              <BookingSuccess/>
            </ProtectedRoute>
          } />
          <Route path="/create-checkout-session" />
          <Route path="/about" element={<About/>} />
          <Route path="/terms-of-service" element={<Tos/>} />
          <Route path="/signup" element={<Register/>} />
          <Route path="/faqs" element={<Faqs/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/:photographers/:continent/:country/:state/:county?/:surfspot" element={<Dashboard_Photographers/>} />
          <Route path="/:continent/:country/:state/:county?/:surfspot" element={<Dashboard />}/>
          <Route path="/profile" element={
            <ProtectedRoute>
              <AuthedUserProfile />
            </ProtectedRoute>
          }/>
          <Route path="/bookings" element={
            <ProtectedRoute>
              <Bookings />
            </ProtectedRoute>
          }/>
          <Route path="/onboarding/:userid" element={
            <ProtectedRoute>
              <Onboarding />
            </ProtectedRoute>
          }/>
          <Route path="/onboarding-start/:userid" element={
            <ProtectedRoute>
              <OnboardingStart />
            </ProtectedRoute>
          }/>
          <Route path="/flmr/:username/:userid/:continent/:country/:state/:county?/:surfspot" element={ <FilmerProfile/> }/>
          <Route path="/albums/:id/:albumspot/:albumname" element={ <Album /> }/>
          <Route path="/profile/:username/:owner" element={ <Profile /> }/>
          <Route path="/myalbums" element={
            <ProtectedRoute>
              <UsersAlbums />
            </ProtectedRoute> 
          }/>
          <Route path="/accountvalidation" element={
            <ProtectedRoute>
              <SignUpValidate />
            </ProtectedRoute> 
          }/>
          <Route path="/validationsuccess" element={
            <SignUpValidateSuccess />
          }/>
          <Route path="/cart" element={
            <ProtectedRoute>
              <Cart />
            </ProtectedRoute> 
          }/>
          <Route path="/login" element={<Login/>} />
          <Route path="/passwordreset" element={<PasswordReset/>} />
        </Routes>
      </Suspense>
      
      <ToastContainer position='bottom-left' />
      </UserAuthContextProvider>
      </Elements>
    </div>
  );
}

export default App;
