import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import IconXOutline from '../Icons/IconXOutline';
import BlankModal from '../Modal/ModalBlank';
import FancyModal from '../Modal/FancyModal';
import { SurferCancelBooking } from '../../Services/firebase/SurferCancelBooking';
import { SurferCancelInProgressBooking } from '../../Services/firebase/SurferCancelInProgressBooking';
import { FlmrCancelInProgressBooking } from '../../Services/firebase/FlmrCancelInProgressBooking';
import { FlmrDeclineBookingRequest } from '../../Services/firebase/FlmrDeclineBookingRequest';
import { FlmrAcceptBooking } from '../../Services/firebase/FlmrAcceptBooking';
import { FlmrCompleteBooking } from '../../Services/firebase/FlmrCompleteBooking';
import { ref as sRef, getDownloadURL, getStorage } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useUserAuth } from '../../Context/UserAuthContext';

interface BookingRequestCardProps {
  document_id: string,
  booking_cost_net?: any,
  booking_status: any,
  canceled?: boolean,
  username: string,
  media_type: string,
  session_length: number,
  surf_spot: string,
  start_date_time: any,
  total_cost: number,
  title?: string,
  content?: string
  avatar?: any,
  booking_user_username: string,
  isFilmer: boolean,
  inProgress?: boolean
  isPendingJob?: boolean 
}

function BookingRequestCard(props: BookingRequestCardProps) {
  let { userProfile, user } = useUserAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false); // For Decline button
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false); // For Cancel Booking button
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false); // For Accept Booking button
  const [isCancelInProgressBookingModalOpen, setCancelInProgressBookingModalOpen] = useState(false);
  const [isFilmerInProgressCancelModalOpen, setIsFilmerInProgressCancelModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);

  const [inProgressAvatar, setInProgressAvatar] = useState<string | undefined>(undefined);
  const [myRequestedBookingsAvatar, setMyRequestedBookingsAvatar] = useState<string | undefined>(undefined);
  const [pendingJobRequestAvatar, setPendingJobRequestAvatar] = useState<string | undefined>(undefined);
  const contentRef = useRef<HTMLDivElement>(null);

  const storage = getStorage();

  const toggleAccordion = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleDeclineClick = () => {
    setIsDeclineModalOpen(true);
  };

  const handleAcceptClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleCancelClick = () => {
    setIsCancelModalOpen(true);
  };

  const handleInProgressCancelClick = () => {
    setCancelInProgressBookingModalOpen(true);
  }

  const handleIsFilmerInProgressCancelClick = () => {
    setIsFilmerInProgressCancelModalOpen(true);
  }

  const handleIsFilmerCompleteClick = () => {
    setIsCompleteModalOpen(true);
  }

  // Fetch 'In Progress Bookings' Avatar
  useEffect(() => {
    if (props.avatar) {
      const profileImgRef = sRef(storage, `user_images/${props.avatar}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          setInProgressAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [props.avatar]);

  // Fetch 'My Requested Bookings' Avatar
  useEffect(() => {
    if (props.avatar) {
      const profileImgRef = sRef(storage, `user_images/${props.avatar}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          setMyRequestedBookingsAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [props.avatar]);

  // Fetch 'Pending Job Requests' Avatar
  useEffect(() => {
    if (props.avatar) {
      const profileImgRef = sRef(storage, `user_images/${props.avatar}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          setPendingJobRequestAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [props.avatar]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : '0px';
    }
  }, [isOpen]);

  return (
    <Wrapper>
      <div className="">
    <div 
      className="flex items-center justify-between w-full text-left py-3 px-4 cursor-pointer" 
      onClick={toggleAccordion}
    >
      <div className="flex items-center dropdown-info-wrapper">
        {props.inProgress ? (
          <img src={inProgressAvatar} className="user-avatar mr-6" />
        ) : props.avatar === user.uid ? (
          <img src={pendingJobRequestAvatar} className="user-avatar mr-6" />
        ) : (
          <img src={myRequestedBookingsAvatar} className="user-avatar mr-6" />
        )}

        {props.isFilmer ? (
          <div>
            <p className="line-height-reset">{props.booking_user_username}</p>
            <h4>{props.start_date_time}</h4>
          </div>
        ) : (
          <div>
            <p className="line-height-reset">{props.username}</p>
            <h4>{props.start_date_time}</h4>
          </div>
        )}
      </div>
      <svg
        className={`w-6 h-6 transition-transform duration-200 ease-in-out ${
          isOpen ? 'transform rotate-180' : ''
        }`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </div>
    <div
      ref={contentRef}
      className="dropdown-content overflow-hidden transition-max-height duration-300 ease-in-out"
      style={{ maxHeight: '0px' }}
    >
      <div className="py-2 px-4 flex items-center section">
        <p><b>Media Type: </b></p>
        <p className="pl-4">{props.media_type}</p>
      </div>
      <div className="py-2 px-4 flex items-center section">
        <p><b>Date + Start Time: </b></p>
        <p className="pl-4">{props.start_date_time}</p>
      </div>
      <div className="py-2 px-4 flex items-center section">
        <p><b>Length: </b></p>
        <p className="pl-4">{props.session_length} Hours</p>
      </div>
      <div className="py-2 px-4 flex items-center section">
        <p><b>Surf Spot: </b></p>
        <p className="pl-4">{props.surf_spot}</p>
      </div>

      {props.isFilmer ? (
        <div className="py-2 px-4 flex items-center section">
          <p><b>Payment To Receive:</b></p>
          <p className="pl-4">${props.booking_cost_net?.toFixed(2)}</p>
        </div>
      ) : (
        <div className="py-2 px-4 flex items-center section">
          <p><b>Payment:</b></p>
          <p className="pl-4">${props.total_cost.toFixed(2)}</p>
        </div>
      )}

      <div className="py-4 px-4 flex items-center section">
        <p><b>Status: </b></p>
        <div className="ml-4 pill">
          <p>{props.booking_status}</p>
        </div>
      </div>

      {/* Only render the buttons if props.canceled is false */}
      {!props.canceled && (
        <>
          {props.isFilmer && !props.inProgress ? (
            <div className="py-4 px-4 flex items-center">
              <ButtonWrapper>
                <button onClick={handleAcceptClick} className="accept">Accept</button>
                <button onClick={handleDeclineClick} className="decline">Decline</button>
              </ButtonWrapper>
            </div>
          ) : props.isFilmer && props.inProgress ? (
            <div className="py-4 px-4 flex items-center">
              <ButtonWrapper>
                <button onClick={handleIsFilmerCompleteClick} className="accept">Complete Job</button>
                <button onClick={handleIsFilmerInProgressCancelClick} className="decline">Cancel Booking</button>
              </ButtonWrapper>
            </div>
          ) : !props.isFilmer && props.inProgress ? (
            <div className="py-4 px-4 flex items-center w-full">
              <ButtonWrapper>
                <button onClick={handleInProgressCancelClick} className="cancel-booking flex items-center">
                  <IconXOutline />Cancel Booking
                </button>
              </ButtonWrapper>
            </div>
          ) : (
            <div className="py-4 px-4 flex items-center w-full">
              <ButtonWrapper>
                <button onClick={handleCancelClick} className="cancel-booking flex items-center">
                  <IconXOutline />Cancel Request
                </button>
              </ButtonWrapper>
            </div>
          )}
        </>
      )}
    </div>
  </div>

      {/* Decline Booking Modal */}
      <FancyModal
        open={isDeclineModalOpen}
        setOpen={setIsDeclineModalOpen}
        title="Decline Booking Request"
        description="Are you sure you want to cancel this booking request? If you are not currently accepting bookings, please turn off your active status in your profile."
        primaryButtonText="Decline Booking"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await FlmrDeclineBookingRequest(selectedBooking);
          setIsDeclineModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsDeclineModalOpen(false)}
      />

      {/* Cancel Booking Modal */}
      <FancyModal
        open={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        title="Cancel Booking Request"
        description="Are you sure you want to cancel this booking request?"
        primaryButtonText="Cancel Request"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await SurferCancelBooking(selectedBooking);
          setIsCancelModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsCancelModalOpen(false)}
      />

      {/* Complete Booking Modal */}
      <FancyModal
        open={isCompleteModalOpen}
        setOpen={setIsCompleteModalOpen}
        title="Complete This Booking"
        description="Please be sure your client has their media in hand before completing this job."
        primaryButtonText="Complete Job"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await FlmrCompleteBooking(selectedBooking);
          setIsCompleteModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsCompleteModalOpen(false)}
      />

      {/* Accept Booking Modal */}
      <FancyModal
        open={isAcceptModalOpen}
        setOpen={setIsAcceptModalOpen}
        title="Accept Booking Request"
        description="Accepting this booking request will lock in the session and the buyer will be charged. Canceling this booking after accepting this request could greatly effect your rating - making future bookings less likely."
        primaryButtonText="Accept Booking"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await FlmrAcceptBooking(selectedBooking);
          setIsAcceptModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsAcceptModalOpen(false)}
      />

      {/* In Progress Cancel by surfer Booking Modal */}
      <FancyModal
        open={isCancelInProgressBookingModalOpen}
        setOpen={setCancelInProgressBookingModalOpen}
        title="Cancel This Booking?"
        description="Canceling this booking will forfeit the processing fee (14%) pre tax of this booking. Are you sure you want to cancel?"
        primaryButtonText="Yes, Cancel Booking"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await SurferCancelInProgressBooking(selectedBooking)
          setCancelInProgressBookingModalOpen(false);
        }}
        onSecondaryButtonClick={() => setCancelInProgressBookingModalOpen(false)}
      />

      {/* In Progress Cancel by flmr Booking Modal */}
      <FancyModal
        open={isFilmerInProgressCancelModalOpen}
        setOpen={setIsFilmerInProgressCancelModalOpen}
        title="Cancel This Booking?"
        description="Are you sure you want to cancel this booking that is already in progress? We really frown down on this as it ruins the integrity of the community we are trying to build. Doing so could effect your future hirability."
        primaryButtonText="Yes, Cancel Booking"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          let selectedBooking = props.document_id;
          await FlmrCancelInProgressBooking(selectedBooking);
          setIsFilmerInProgressCancelModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsFilmerInProgressCancelModalOpen(false)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #3c444c;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;

  .line-height-reset {
    line-height: normal;
  }

  .info-separator {
    border-bottom: 1px solid #3c444c;
  }

  .user-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  
  .dropdown-info-wrapper p {
    margin-right: 2rem;
  }

  .dropdown-content {
    background-color: #292F35;
  }

  .section {
    border-bottom: 1px solid #3c444c;
  }

  svg {
    stroke: #CBCFD4;
  }

  .pill {
    padding: .25rem .75rem;
    background-color: #30363d;
    border-radius: 7px;
  }

  .pill p {
    font-size: 12px;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;

  .accept {
    background-color: green;
    color: #CBCFD4;
    padding: .25rem 1rem;
    border-radius: 4px;
    margin-right: 1rem;
  }

  .decline {
    background-color: red;
    color: #CBCFD4;
    padding: .25rem 1rem;
    border-radius: 4px;
    margin-right: 1rem;
  }

  svg {
    fill: #CBCFD4;
    margin: 0 1rem 0 0;
  }

  .cancel-booking {
    background-color: red;
    border-radius: 4px;
    padding: .25rem 1rem;
    color: #CBCFD4;
    width: max-content;
  }
`

export default BookingRequestCard
