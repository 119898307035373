import React from 'react'
import styled from 'styled-components'

interface PhotoCardCostProps {
  photoCost: string | number;
}

function PhotoCardCost(props: PhotoCardCostProps) {
  return (
    <Wrapper className="shadow-lg">
      <p><b>${props.photoCost}</b></p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 40px;
  width: 65px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #30363dd1;
  /* background: linear-gradient(57deg,#F27A54 0%,#A154F2 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
`

export default PhotoCardCost