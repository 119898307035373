import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

interface ToggleFlmrActiveStatusProps {
  user: any;
  status: boolean;
}

export async function ToggleFlmrActiveStatus(user: any, status: boolean) {
  const db = getFirestore();

  try {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      
      await updateDoc(userDocRef, {
        flmr_available: status
      });

      if (status) {
        toast.success("You are now accepting bookings.", { theme: "dark" });
      } else {
        toast.error("Bookings turned off.", { theme: "dark" });
      }
    } else {
      throw new Error("User is not authenticated");
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    toast.error("Failed to update booking status.", { theme: "dark" });
  }
}
