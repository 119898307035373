import { useState } from 'react';

type WatermarkImageHookResult = {
  watermarkedImages: any[];
  handleFileInputChange: (
    files: FileList,
    progressCallback: (progress: number) => void
  ) => Promise<void>;
};

const useWatermarkImage = (): WatermarkImageHookResult => {
  const [watermarkedImages, setWatermarkedImages] = useState<string[]>([]);

  const handleFileInputChange = (
    files: FileList,
    progressCallback: (progress: number) => void
  ): Promise<void> => {
    return new Promise<void>((resolve) => {
      const totalFiles = files.length;
      let processedFiles = 0;

      const processImage = (file: File) => {
        return new Promise<void>((imageResolve) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const img = new Image();

          const watermarkText = 'FLMR';
          const watermarkOpacity = 0.3;

          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            // draw the original image on the canvas
            ctx.drawImage(img, 0, 0);

            // add the watermark text
            const watermarkFontSize = Math.max(20, canvas.width / 10);
            ctx.font = `${watermarkFontSize}px Righteous`;
            ctx.fillStyle = `rgba(255,255,255,${watermarkOpacity})`;

            // save the context state
            ctx.save();

            // rotate the canvas context by 45 degrees
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI / 4);
            ctx.translate(-canvas.width / 2, -canvas.height / 2);

            // calculate the text position
            const watermarkTextWidth = ctx.measureText(watermarkText).width;
            const watermarkTextHeight = watermarkFontSize * 1.5; // estimated height
            const watermarkPadding = 0; // padding around edges
            const xCount = Math.ceil(
              (canvas.width - 2 * watermarkPadding) / watermarkTextWidth
            );
            const yCount = Math.ceil(
              (canvas.height - 2 * watermarkPadding) / watermarkTextHeight
            );
            const startX =
              (canvas.width - xCount * watermarkTextWidth) / 2;
            const startY =
              (canvas.height - yCount * watermarkTextHeight) / 2;

            // draw the text
            for (let i = 0; i < xCount; i++) {
              for (let j = 0; j < yCount; j++) {
                const x = startX + i * (watermarkTextWidth + 175);
                const y = startY + j * (watermarkTextHeight + 175);
                ctx.fillText(watermarkText, x, y);
              }
            }

            // restore the context state
            ctx.restore();

            // convert the canvas to a data URL
            const watermarkedImageUrl = canvas.toDataURL('image/jpeg', 0.9);

            setWatermarkedImages((prevWatermarkedImages) => [
              ...prevWatermarkedImages,
              watermarkedImageUrl,
            ]);
            processedFiles++;
            const progress = (processedFiles / totalFiles) * 100;
            progressCallback(progress);

            if (processedFiles === totalFiles) {
              // All files processed, indicate completion
              progressCallback(100);
              resolve();
            } else {
              // Process the next image in the queue
              processImage(files[processedFiles]).then(imageResolve);
            }
          };

          img.src = URL.createObjectURL(file);
        });
      };

      // Start processing the first image in the queue
      processImage(files[processedFiles]);
    });
  };

  return {
    watermarkedImages,
    handleFileInputChange,
  };
};

export default useWatermarkImage;
