import React, { useState } from 'react';


function IconArrowRight(props) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = hovered ? '#F27A54' : '#31373E';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition,
    ...props.style
  }

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'pointer', ...props.style}}>
      <svg width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
      <path 
        d="M2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14C4.6925 14 2 11.3075 2 8ZM0.5 8C0.499999 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5C3.86 0.499999 0.5 3.86 0.5 8ZM8 7.25L5 7.25L5 8.75L8 8.75L8 11L11 8L8 5L8 7.25Z" 
        fill={fill}
        style={svgStyle}/>
      </svg>
    </div>
  );
}

export default IconArrowRight;