import React, { useState } from 'react';

function IconView() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = hovered ? '#F27A54' : '#31373E';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
      <path 
        d="M24.6001 16.4785V22.1785C24.6005 22.3391 24.5676 22.4981 24.5031 22.6464C24.4386 22.7948 24.3439 22.9296 24.2245 23.0431C24.105 23.1566 23.9631 23.2466 23.8069 23.3078C23.6507 23.3691 23.4833 23.4004 23.3144 23.4H17.3144C16.1685 23.4 15.5953 22.0818 16.4037 21.3134L18.343 19.471L12.6001 14.0153L6.85564 19.4761L8.79653 21.3134C9.60492 22.0818 9.03171 23.4 7.88581 23.4H1.88582C1.71685 23.4004 1.54947 23.3691 1.39329 23.3078C1.23711 23.2466 1.09521 23.1566 0.975733 23.0431C0.856259 22.9296 0.76157 22.7948 0.697107 22.6464C0.632644 22.4981 0.599678 22.3391 0.600102 22.1785V16.4785C0.600102 15.3894 1.98707 14.8449 2.79653 15.6134L4.73528 17.4557L10.4819 12L4.73474 6.53917L2.79653 8.38658C1.9876 9.15506 0.600102 8.61051 0.600102 7.5214V1.82141C0.599678 1.66089 0.632644 1.50188 0.697107 1.35351C0.76157 1.20514 0.856259 1.07033 0.975733 0.956829C1.09521 0.843329 1.23711 0.753375 1.39329 0.692135C1.54947 0.630895 1.71685 0.599577 1.88582 0.599979H7.88581C9.03171 0.599979 9.60492 1.9181 8.79653 2.68659L6.85724 4.52891L12.6001 9.98462L18.3446 4.52382L16.4037 2.68659C15.5953 1.9181 16.1685 0.599979 17.3144 0.599979H23.3144C23.4833 0.599577 23.6507 0.630895 23.8069 0.692135C23.9631 0.753375 24.105 0.843329 24.2245 0.956829C24.3439 1.07033 24.4386 1.20514 24.5031 1.35351C24.5676 1.50188 24.6005 1.66089 24.6001 1.82141V7.5214C24.6001 8.61051 23.2131 9.15506 22.4037 8.38658L20.4649 6.54426L14.7183 12L20.4655 17.4608L22.4037 15.6185C23.2126 14.8449 24.6001 15.3894 24.6001 16.4785Z" 
        fill={fill}
        style={svgStyle}/>
      </svg>
    </div>
  );
}

export default IconView;