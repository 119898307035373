import React, { useState } from "react";
import PrimaryButton from '../Buttons/PrimaryButton';
import styled from "styled-components";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/271adf80-7bd0-11ed-ae39-e5d94069e299";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <ThankYouWrapper>
        <div className="text-2xl text-white">Thanks for reaching out</div>
        <div className="text-md text-white">We'll be in touch as soon as possible.</div>
      </ThankYouWrapper>
    );
  }

  return (
    <ContactWrapper>
      <form
        action={FORM_ENDPOINT}
        onSubmit={handleSubmit}
        method="POST"
      >
        {/* Grid */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <input type="text" placeholder="First Name" name="name" required />
          </div>
          <div>
            <input type="text" placeholder="Last Name" name="name" required />
          </div>
          <div>
            <input type="text" placeholder="Subject" name="subject" required />
          </div>
          <div>
            <input type="email" placeholder="Email" name="email" required />
          </div>
        </div>
        {/* End Grid */}
        <div>
          <textarea placeholder="Your message" name="message" required />
        </div>
        <div>
          <PrimaryButton type="submit">
            Send Message
          </PrimaryButton>
        </div>
      </form>
    </ContactWrapper>
  );
};

const ContactWrapper = styled.div`
  width: 100%;
  
  form {
    border-radius: 3px;
    
  }

  input, textarea {
    border-radius: 2px;
    background-color: #30363D;
    color: #9ca3af;
    width: 100%;
    padding: 0rem 1rem;
    font-size: 12px;
  }

  input {
    height: 35px;
  }
  /* TODO: Move inputs to reusable component */
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #30363D inset;
    border: 2px solid #30363D; // This is a hack necessary to account for the white edges created by the autofill. Needs to match background color
    -webkit-text-fill-color: #9ca3af;
  }
  input:focus, textarea:focus {
    outline: 1px solid #9ca3af;
}

  textarea {
    margin: 1rem 0rem;
    height: 65px;
    padding-top: 10px;
  }
`

const ThankYouWrapper = styled.div`
  width: 85%;
  text-align: center;
  background-color: #3C444C;
  padding: 2rem;
  border-radius: 3px;
`

export default ContactForm;