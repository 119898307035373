import React from 'react'
import styled from 'styled-components'

function IconXOutline(props) {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill={props.fill} className={props.class} d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"/></svg>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  transition: fill .3s ease-in-out;  
  svg {
    width: 14px;
    fill: #30363D;
    margin: 0px 12px;
  }
`
export default IconXOutline
