import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getFirestore, collection, getDocs, where, query } from "firebase/firestore";
import DashboardInfoBar from '../../Components/DashboardInfoBar/DashboardInfoBar';
import AlbumCard from '../../Components/AlbumCard/AlbumCard';
import SpotBar from '../../Components/SpotBar/SpotBar';

function Dashboard() {

  const [queriedAlbums, setQueriedAlbums] = useState([]);
  const firestoredb = getFirestore();
  const MemoizedAlbumCard = React.memo(AlbumCard);
  const { surfspot } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (surfspot) {
        const q = query(collection(firestoredb, "albums"), where("album_spot", "==", surfspot));
        const querySnapshot = await getDocs(q);
        const albums = [];
        querySnapshot.forEach((doc) => {
          albums.push(doc.data());
        });
        // Sort Albums
        albums.sort((a, b) => {
          const dateA = new Date(a.session_date);
          const dateB = new Date(b.session_date);
          return dateB - dateA;
        });
        setQueriedAlbums(albums);
      }
    };

    fetchData();

    // Return a cleanup function if needed
    return () => {
      // Perform any necessary cleanup here
    };
  }, [surfspot, firestoredb]);
  
  return (
    <>
    <SpotBar selectedSpot={surfspot} />
    <UserListWrapper>
      <DashboardInfoBar surfspot={surfspot} filmerCount={'null'} />
      {/* <HRFancy /> */}
      <UserItemWrapper>
        {/* No albums message */}
        { queriedAlbums.length > 0 ? null : 
          <NoSpotsWrapper>
            <h2>There haven't been any albums uploaded to this spot yet.</h2>
          </NoSpotsWrapper> }
        {/* Album Cards Displayed Here */}
        <CardWrapper className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
            {queriedAlbums.map((item, index) => {
              return <MemoizedAlbumCard
                        key={index}
                        album_continent={item.album_continent}
                        album_country={item.album_country}
                        album_county={item.album_county}
                        album_default_banner={item.album_default_banner}
                        album_description={item.album_description}
                        album_media_count={item.album_media_count}
                        album_media_price={item.album_media_price}
                        album_name={item.album_name}
                        album_owner_id={item.album_owner_id}
                        album_owner_username={item.album_owner_username}
                        album_slug={item.album_slug}
                        album_spot={item.album_spot}
                        album_state={item.album_state}
                        album_upload_date={item.album_upload_date}
                        session_date={item.session_date}
                        session_time={item.session_time}
                      />
            })
          }
        </CardWrapper>
      </UserItemWrapper>
    </UserListWrapper>
    </>
  )
}

const UserItemWrapper = styled.div`
  padding-top: 1rem;
`
const NoSpotsWrapper = styled.div`
  color: #30363d;
  background-color: #3C444C;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  border-radius: 4px;
`

const DashHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
`

const CardWrapper = styled.div`
  position: relative;
  gap: 1rem;
  border-radius: 3px;
`

const UserListWrapper = styled.div`
  padding: 15px 0px 20px 0px;
  margin: 0rem 1.5rem;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    font-size: 24px;
    font-weight: 700;
  }

  .user {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 1em;
    border-bottom: 1px solid #bfbfbf;
    align-items: center;
  }
`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
`

export default Dashboard




