import { getFirestore } from 'firebase/firestore';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import styled from 'styled-components'
import { useUserAuth } from '../../Context/UserAuthContext';
import BlankModal from '../Modal/ModalBlank';
import FancyModal from '../Modal/FancyModal';
import { useNavigate, useParams } from 'react-router-dom';
import { NewBooking } from '../../Services/firebase/NewBooking';

interface ReservationSummaryProps {
  preTaxTotal: number,
  mediaType: string,
  transactionFee: number,
  sessionDate: Date,
  orderTotal: number,
  bookingLength: number,
  hourly_rate: number,
  booking_user_username: string,
  booking_user_id: string
}

function ReservationSummary(props: ReservationSummaryProps) {

  const db = getFirestore();
  let { user, userProfile } = useUserAuth(); 

  const { continent, country, state, county, surfspot, username, userid }: any = useParams();
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const navigate = useNavigate();

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'  // Adds the timezone name (e.g., EST, GMT)
  }).format(props.sessionDate);

  const bookingData = {
    booking_continent: continent,
    booking_cost_net: props.preTaxTotal, // Ensure two decimal places
    booking_cost_total: props.preTaxTotal + props.transactionFee, // Ensure two decimal places
    booking_country: country,
    booking_county: county,
    booking_fees: parseFloat(props.transactionFee.toFixed(2)),
    booking_hourly_rate: props.hourly_rate,
    booking_length: props.bookingLength,
    booking_media_type: props.mediaType,
    booking_start_date_time: formattedDate,
    booking_state: state,
    booking_status: 'pending_stripe_payment_intent',
    booking_surf_spot: surfspot,
    booking_timestamp: Date(),
    booking_user_id: user?.uid,
    booking_user_email: user?.email,
    booking_user_username: userProfile.username,
    flmr_id: userid,
    flmr_username: username
  };

  function handleBookFlmrClick() {
    if (!props.mediaType || !props.sessionDate || !props.bookingLength) {
      toast.warning("Please fill out all info.", {
        theme: "dark"
      }
    )} else {
      setBookingModalOpen(true);
    }
  };

  const handleCheckout = async () => {
    // Create the booking and get its ID
    const bookingId = await NewBooking(bookingData);
  
    if (bookingId) {
      const response = await fetch('/.netlify/functions/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: Number(bookingData.booking_cost_total.toFixed(2)),
          currency: 'usd',
          bookingId
        }),
      });
  
      const { url } = await response.json();
      window.location.href = url;  // Redirect to Stripe Checkout page
    } else {
      toast.error("Booking creation failed. Please try again.", {
        theme: "dark"
      });
    }
  };  

  return (
    <Wrapper className='shadow-lg'>
      {/* <div className="section">
        <div><h5>SESSION SUMMARY</h5></div>
      </div> */}
      <div className="section">
        <h5>DELIVERY METHOD</h5>
        <div className="flex justify-between">
          <p>Instant Download</p>
          <p>FREE</p>
        </div>
      </div>
      <div className="section">
        <h5>BOOKING INFO</h5>
        <div className="flex justify-between">
          <p>Session Date</p>
          <p>{formattedDate}</p>
        </div>
        <div className="flex justify-between">
          <p>Session Length</p>
          <p>{props.bookingLength} Hours</p>
        </div>
        <div className="flex justify-between">
          <p>Media Type</p>
          <p>{props.mediaType}</p>
        </div>
      </div>
      <div className="section">
        <h5>TOTAL</h5>
        <div className="flex justify-between">
          <p>Amount</p>
          <p>${props.preTaxTotal}</p>
        </div>
        <div className="flex justify-between">
          <p>Tax</p>
          <p>Calculated at Checkout</p>
        </div>
        <div className="flex justify-between">
          <p>Transcation Fee (14%)</p>
          <p>${props.transactionFee.toFixed(2)}</p>
        </div>
      </div>
      <div className="section no-border">
        <div className="flex justify-between items-center">
          <h5>ORDER TOTAL</h5>
          <p>${(props.preTaxTotal + props.transactionFee).toFixed(2)}</p>
        </div>
        <p className="text-xs">You will only be billed once the booking is accepted.</p>
      </div>
      <SummaryCheckoutWrapper className="flex flex-col">
        {/* <CouponBtn>ADD COUPON CODE HERE</CouponBtn> */}
        <CheckoutBtn onClick={handleBookFlmrClick}>BOOK FLMR</CheckoutBtn>
        {/* <PaymentElement /> */}
      </SummaryCheckoutWrapper>
      <FancyModal
        open={bookingModalOpen}
        setOpen={setBookingModalOpen}
        title="Booking Confirmation"
        description="Please closely review your booking information before booking. You won't be able to edit your request. Payment will be collected upon the booking being accepted by the other party."
        primaryButtonText="Book Session"
        secondaryButtonText="Close"
        onPrimaryButtonClick={async () => {
          handleCheckout();
        }}
        onSecondaryButtonClick={() => setBookingModalOpen(false)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`

  background-color: #3C444C;
  border-radius: 4px;

  h5 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 16px;
    color: white;
  }

  .section {
    padding: 1.5rem;
    border-bottom: 1px solid #30363D;
  }

  .section .item-counter {
    color: #F27A54;
  }

  .no-border {
    border: none;
  }

  .checkout-btn {
    
  }
`

const SummaryCheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const CouponBtn = styled.button`
  background-color: #30363D;
  height: 50px;
  border-radius: 7px;
  margin-bottom: 1rem;
  color: #CBCFD4;
  font-size: 12px;
`
const CheckoutBtn = styled.button`
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
  height: 50px;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  font-weight: 500;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;

  .accept {
    background-color: green;
    color: #CBCFD4;
  }

  .decline {
    background-color: red;
    color: #CBCFD4;
  }
 
  button {
    padding: .25rem 1rem;
    border-radius: 4px;
    margin-right: 1rem;
  }
`

export default ReservationSummary