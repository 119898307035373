import React from 'react'
import styled from 'styled-components';

interface SpotChipProps {
  statusColor?: string;
  spotName: string;
  key: number,
  className?: string,
  onClick?: ((spot?: string) => void) | undefined;
}

function SpotChip(props: SpotChipProps) {
  return (
    <Wrapper onClick={() => props.onClick?.(props.spotName)} className={props.className}>
      <Indicator statusColor={props.statusColor}/>
      <p>{props.spotName}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 37px;
  background-color: #3c444c;
  border-radius: 20px;
  padding: 0rem 1rem;
  transition: background-color 0.1s ease-in-out;

  p {
    color: #CBCFD4;
    font-size: 12px;
  }

  :hover {
    background-color: #30363d;
  }
`

const Indicator = styled.div<{ statusColor?: string }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 7px;
  /* Hide background when we want to start using different color indicators */
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
  background-color: ${props => props.statusColor};
`

export default SpotChip