import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import CartItemCard from '../../Components/CartItemCard/CartItemCard';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import CartSummary from '../../Components/CartSummary/CartSummary';
import { toast } from 'react-toastify';
import CreateAlbumBtn from '../../Components/Buttons/CreateAlbumBtn';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { useUserAuth } from '../../Context/UserAuthContext';

function Cart() {

  const db = getFirestore();
  let { user } = useUserAuth();    
  const [cartItems, setCartItems] = useState([]);

  // Grab the items in the users cart
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (user && user.uid) {
          const cartItemsRef = collection(db, 'carts', user.uid, 'items');
          const unsubscribe = onSnapshot(cartItemsRef, (querySnapshot) => {
            const itemsData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setCartItems(itemsData);
          });
          // Return a cleanup function to unsubscribe from the snapshot listener
          return () => unsubscribe();
        }
      } catch (error) {
        toast.error(error, {
          theme: "dark"
        });
      }
    };

    fetchCartItems();
  }, [db, user]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <h1>My Cart</h1>
        <div className="p-4">
          {/* <CreateAlbumBtn /> */}
        </div>
      </HeaderWrapper>
      <HRFancy />
      <CheckoutMessage>
        <p><b>Notice:</b> We are currently still building out our checkout process. To purchase the images in your cart, please reach out via <a href="https://www.instagram.com/flmr.app/" target='blank'><b>instagram</b></a> or <a href='https://www.flmr.app/contact'><b>email</b></a> with your username and we will get the photos to you right away.</p>
      </CheckoutMessage>
      <div className="md:grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-8 xl:grid-cols-8">
        <div className="col-span-5">
        { cartItems.length > 0 
          ? cartItems.map((item, index) => (
              <CartItemCard
                key={index}
                id={item.id}
                owner={item.owner}
                albumName={item.albumName}
                itemName={item.itemName}
                itemCost={item.imagePrice}
                itemFileType={item.itemFileType}
                itemThumbnail={item.imageURL}
                photographer={item.photographerName}
              />
            ))
          : 
          <NoSpotsWrapper>
            <h2>There is nothing in your cart.</h2>
          </NoSpotsWrapper> 
        }
               
        </div>
        <div className="col-span-3">
          <CartSummary />
          {/* <PaymentForm
          applicationId="sandbox-sq0idb-YOLG0c2cIeSOX5wnbcBbxA"
          cardTokenizeResponseReceived={(token, verifiedBuyer) => {
            console.log('token:', token);
            console.log('verifiedBuyer:', verifiedBuyer);
          }}
          locationId='LC3R4SB9RKG5M'
          >
          <CreditCard />
          </PaymentForm> */}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 1rem;

  .content {
    justify-content: space-between;
  }

  h1 {
    color: #CBCFD4;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C444C;
  border-radius: 3px;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    padding: 1rem;
    font-size: 24px;
    font-weight: 900;
  }
`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
  margin-bottom: 1rem;
`

const NoSpotsWrapper = styled.div`
  color: #30363d;
  background-color: #3C444C;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 1rem;
`

const CheckoutMessage = styled.div`
  background-color: #ff000070;
  padding: 1rem 0.5rem;
  margin-bottom: 15px;
  border-radius: 3px;
  height: auto;
  display: flex;
  align-items: center;

  p {
    padding-left: 15px;
  }
`

export default Cart