import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

interface FlmrAcceptBooking {
  // You can define specific properties if needed
}

export async function FlmrAcceptBooking(document_id: string): Promise<void> {
  const db = getFirestore();

  try {
    // Reference to the document in the 'bookings' collection
    const bookingDocRef = doc(db, "bookings", document_id);

    // Update the booking_status field
    await updateDoc(bookingDocRef, {
      booking_status: 'accepted_in_progress'
    });

    toast.success("Booking Confirmed", {
      theme: "dark",
    });
  } catch (error) {
    console.error("Error accepting booking:", error);
    toast.error("Failed to accept booking", {
      theme: "dark",
    });
  }
}
