import React, { useEffect, useRef, useState } from 'react';
import { useUserAuth } from '../../Context/UserAuthContext';
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import SpotChip from '../SpotChip/SpotChip';
import IconArrowRight from '../Icons/IconArrowRight';
import IconArrowLeft from '../Icons/IconArrowLeft';
import SurfSpots from '../../Data/SurfSpots_New.json';

function SpotBar({ selectedSpot }) {

  const firestoredb = getFirestore();
  const { user, updateCurrentUserSpot } = useUserAuth();
  const chipContainerRef = useRef(null);
  const navigate = useNavigate();
  const { continent, country, county, state, surfspot, photographers } = useParams();
  const [activeChip, setActiveChip] = useState(null);

  const selectedContinentObject = SurfSpots.find(continentData => continentData.continent === continent);
  const selectedCountryObject = selectedContinentObject?.countries?.find(countries => countries.name === country);
  const selectedStateObject = selectedCountryObject?.states?.find(stateData => stateData.name === state);
  const selectedCountyObject = selectedStateObject?.counties?.find(counties => counties.name === county);

  const sortedSurfSpots = selectedCountyObject
    ? selectedCountyObject?.surfSpots.sort()
    : selectedStateObject?.surfSpots.sort();
  
  const handleScrollLeft = () => {
    if (chipContainerRef.current) {
      chipContainerRef.current.scrollBy({
        left: -200, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  };

  const handleScrollRight = () => {
    if (chipContainerRef.current) {
      chipContainerRef.current.scrollBy({
        left: 200, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    setActiveChip(surfspot);
    
    if (chipContainerRef.current && activeChip) {
      const activeChipElement = chipContainerRef.current.querySelector(`.active_chip`);
      if (activeChipElement) {
        activeChipElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }
  }, [surfspot, activeChip]);

  const handleSpotChipClick = async (spot) => {
    updateCurrentUserSpot(spot);
    if (user) {
      let updateSpotRef = doc(firestoredb, "users", user.uid);
      await updateDoc(updateSpotRef, {
        current_spot: spot
      })
    }
    if (photographers) {
      navigate(`/photographers/${continent}/${country}/${state}${county ? `/${county}` : ''}/${spot}`);
    } else {
      navigate(`/${continent}/${country}/${state}${county ? `/${county}` : ''}/${spot}`);
    }
    setActiveChip(spot);
  }

  useEffect(() => {
    setActiveChip(surfspot);
  }, [surfspot]);

  return (
    <Wrapper>
      <ChipContainer ref={chipContainerRef}>
        {sortedSurfSpots?.map((spot, index) => (
          <SpotChip 
            key={index} 
            spotName={spot} 
            statusColor='#c7c7c7'
            onClick={handleSpotChipClick} 
            className={activeChip === spot ? 'active_chip' : null}
          />
        ))}
      </ChipContainer>
      <ArrowContainer>
        <div onClick={handleScrollLeft}><IconArrowLeft /></div>
        <div onClick={handleScrollRight}><IconArrowRight style={{marginLeft: '15px'}}/></div>
      </ArrowContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: #3c444c;
  padding: 0rem 7rem 0rem 1.5rem;
  height: 60px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Added for smooth scrolling on iOS */

  /* Hide Scrollbar */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  } // Chrome, Safari, Opera 
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE 10+

  .active_chip {
    background-color: #30363d;
  }
`
const ChipContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  } // Chrome, Safari, Opera 
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE 10+
`

const ArrowContainer = styled.div`
  position: absolute;
  right: 0;
  height: 60px;
  background-color: #3c444c;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 0rem 1.5rem;
`

export default SpotBar;
