import React from 'react'

function IconStar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 17" fill="none">
  <defs>
    <linearGradient id="paint0_linear_1380_223" x1="4.29999" y1="0.474609" x2="12.875" y2="16.1496" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F27A54"/>
      <stop offset="1" stopColor="#A154F2"/>
    </linearGradient>
  </defs>
  <path d="M4.29999 16.1246C3.91666 16.4246 3.52499 16.4329 3.12499 16.1496C2.72499 15.8663 2.59166 15.4913 2.72499 15.0246L4.14999 10.3996L0.524992 7.82461C0.124992 7.54128 0.00432555 7.16628 0.162992 6.69961C0.320992 6.23294 0.641659 5.99961 1.12499 5.99961H5.59999L7.04999 1.19961C7.13333 0.966276 7.26266 0.786943 7.43799 0.66161C7.61266 0.536943 7.79999 0.474609 7.99999 0.474609C8.19999 0.474609 8.38733 0.536943 8.56199 0.66161C8.73733 0.786943 8.86666 0.966276 8.94999 1.19961L10.4 5.99961H14.875C15.3583 5.99961 15.6793 6.23294 15.838 6.69961C15.996 7.16628 15.875 7.54128 15.475 7.82461L11.85 10.3996L13.275 15.0246C13.4083 15.4913 13.275 15.8663 12.875 16.1496C12.475 16.4329 12.0833 16.4246 11.7 16.1246L7.99999 13.2996L4.29999 16.1246Z" fill="url(#paint0_linear_1380_223)"/>
</svg>

  )
}

export default IconStar