import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore, doc, onSnapshot, getDoc, setDoc } from "firebase/firestore";
import { getDatabase, ref } from "firebase/database";
import { auth } from "../Services/firebase/FirebaseConfig";

const userAuthContext = createContext();
const firestoredb = getFirestore();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState();
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const dbRef = ref(getDatabase());

  // Setting user to the current auth state from firebase
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // Firestore - Listen for changes in user document
  useEffect(() => {
    if (user) {
      const userRef = doc(firestoredb, "users", user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setUserProfile(doc.data());
        } else {
          console.log("No such document!");
        }
      });

      // Clean up the subscription when the component unmounts
      return () => unsubscribe();
    }
  }, [user]);

  // Function to update Firestore and local state
  const updateUserProfile = (dataToUpdate) => {
    if (user) {
      const userRef = doc(firestoredb, "users", user.uid);
      setDoc(userRef, dataToUpdate, { merge: true })
        .catch((error) => {
          console.error("Error updating Firestore document:", error);
        });
    }
  };

  const updateCurrentUserContinent = (location) => {
    updateUserProfile({ current_continent: location });
  };

  const updateCurrentUserSpot = (location) => {
    updateUserProfile({ current_spot: location });
  };

  const updateCurrentUserCountry = (location) => {
    updateUserProfile({ current_country: location });
  };

  const updateCurrentUserCounty = (location) => {
    updateUserProfile({ current_county: location });
  };

  const updateCurrentUserState = (location) => {
    updateUserProfile({ current_state: location });
  };

  // Other auth functions
  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      throw error;
    });
  }

  function googleLogin() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  return (
    <userAuthContext.Provider 
      value={{
        user,
        userProfile,
        loading,
        logIn,
        signUp,
        logOut,
        googleLogin,
        updateCurrentUserContinent,
        updateCurrentUserSpot,
        updateCurrentUserCountry,
        updateCurrentUserCounty,
        updateCurrentUserState
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
