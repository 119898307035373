import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { toast } from 'react-toastify';

interface PendingBookingReqs {
  document_id: string;
  [key: string]: any;
}

export async function PendingBookingReqs(user: any): Promise<PendingBookingReqs[]> {
  const db = getFirestore();

  try {
    const bookingReqsCollectionRef = collection(db, "bookings");
    
    // Create a query that filters for documents where booking_status is 'pending'
    const q = query(
      bookingReqsCollectionRef,
      where("booking_user_id", "==", user.uid),
      where("booking_status", "==", "pending") // Filter for 'pending' status
    );
    
    const querySnapshot = await getDocs(q);

    // Map the documents to include their IDs
    const bookingReqs = querySnapshot.docs.map((doc) => ({
      document_id: doc.id,
      ...doc.data(),
    }));

    return bookingReqs;
  } catch (error) {
    console.error("Error fetching booking requests:", error);
    toast.error("Failed to fetch booking requests", {
      theme: "dark",
    });
    return [];
  }
}
