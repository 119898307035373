import React from 'react'

function IconJellyfish() {
  return (
    <svg width="325" height="414" viewBox="0 0 325 414" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M103.768 192.5H54.7683C41.2681 211.5 30.2685 257.5 45.2686 282.5C66.2685 313.5 85.3554 317.552 73.2683 340C62.7685 359.5 45.2686 379.666 42.7683 390.5C38.2683 410 61.7683 422.5 73.2683 407C84.7683 391.5 115.268 352.5 113.768 326.5C111.57 288.401 65.2804 280.611 81.7683 233.5C89.8458 210.42 97.1017 196 103.768 192.5Z" fill="#CBCFD4"/>
    <path d="M195.475 192.5H146.475C132.975 211.5 121.976 257.5 136.976 282.5C157.976 313.5 177.062 317.552 164.975 340C154.476 359.5 136.976 379.666 134.475 390.5C129.975 410 153.475 422.5 164.975 407C176.475 391.5 206.975 352.5 205.475 326.5C203.277 288.401 156.987 280.611 173.475 233.5C181.553 210.42 188.809 196 195.475 192.5Z" fill="#CBCFD4"/>
    <path d="M287.475 192.5H238.475C224.975 211.5 213.976 257.5 228.976 282.5C249.976 313.5 269.062 317.552 256.975 340C246.476 359.5 228.976 379.666 226.475 390.5C221.975 410 245.475 422.5 256.975 407C268.475 391.5 298.975 352.5 297.475 326.5C295.277 288.401 248.987 280.611 265.475 233.5C273.553 210.42 280.809 196 287.475 192.5Z" fill="#CBCFD4"/>
    <path d="M173.061 195.5C142.121 195.5 72.2572 195.5 40.3193 195.5C0.39691 195.5 26.1758 100.093 58.7834 65.2507C87.5615 34.5 114.061 20 168.061 20" stroke="#CBCFD4" stroke-width="40"/>
    <path d="M151.062 195.5C182.002 195.5 251.866 195.5 283.804 195.5C323.726 195.5 297.947 100.093 265.34 65.2507C236.562 34.5 210.062 20 156.062 20" stroke="#CBCFD4" stroke-width="40"/>
    </svg>
  )
}

export default IconJellyfish