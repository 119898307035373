import React, { useState } from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { PrevButton, NextButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';

type PropType = {
  slides: string[];
  options?: EmblaOptionsType;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  const [mediaTypes, setMediaTypes] = useState<{ [key: number]: 'image' | 'video' }>({});

  const handleMediaError = (index: number) => {
    setMediaTypes((prev) => ({ ...prev, [index]: 'image' }));
  };

  return (
    <section className="embla relative">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((url, index) => (
            <div className="embla__slide" key={index}>
              {mediaTypes[index] === 'video' ? (
                <video
                  className="embla__slide__video"
                  src={url}
                  style={{height: "250px", width: "100%", objectFit: "cover"}}
                  controls
                  onError={() => handleMediaError(index)}
                />
              ) : (
                <img
                  className="embla__slide__img"
                  src={url}
                  alt="Slide"
                  onError={() => setMediaTypes((prev) => ({ ...prev, [index]: 'video' }))}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="embla__buttons-wrapper absolute top-1/2 left-0 right-0 transform -translate-y-1/2 flex justify-between px-4 pointer-events-none">
        <div className="pointer-events-auto">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        </div>
        <div className="pointer-events-auto">
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>


      <div className="embla__controls absolute bottom-0 left-0 right-0 flex justify-center pb-4 pointer-events-none">
        <div className="embla__dots pointer-events-auto">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={`embla__dot ${index === selectedIndex ? 'embla__dot--selected' : ''}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
