import React, { useState } from 'react';

function IconUpload() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = '#3c444c';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <svg width="25" height="25" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
      <path 
        d="M22.6562 18.75H2.34375C1.04932 18.75 0 17.7007 0 16.4062V2.34375C0 1.04932 1.04932 0 2.34375 0H22.6562C23.9507 0 25 1.04932 25 2.34375V16.4062C25 17.7007 23.9507 18.75 22.6562 18.75ZM5.46875 2.73438C3.95859 2.73438 2.73438 3.95859 2.73438 5.46875C2.73438 6.97891 3.95859 8.20312 5.46875 8.20312C6.97891 8.20312 8.20312 6.97891 8.20312 5.46875C8.20312 3.95859 6.97891 2.73438 5.46875 2.73438ZM3.125 15.625H21.875V10.1562L17.6018 5.88306C17.373 5.65425 17.002 5.65425 16.7731 5.88306L10.1562 12.5L7.44556 9.78931C7.21675 9.5605 6.84575 9.5605 6.61689 9.78931L3.125 13.2812V15.625Z" 
        fill={fill}
        style={svgStyle}/>
      </svg>
    </div>
  );
}

export default IconUpload;