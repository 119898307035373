import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  title: string;
  bookingStatus: string;
  stripeStatus: string;
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ title, bookingStatus, stripeStatus, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : '0px';
    }
  }, [isOpen]);

  return (
    <Wrapper>
      <div
        className="flex items-center justify-between w-full text-left py-3 px-4 cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="flex-col">
          <div className="text-white">{title}</div>
          <div className="flex">Booking Status - <p className="booking-status"> {bookingStatus}</p></div>
          <div className="flex">Stripe Status - <p className="booking-status"> {stripeStatus}</p></div>
        </div>
        
        <svg
          className={`w-6 h-6 transition-transform duration-200 ease-in-out ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
      <div
        ref={contentRef}
        className="dropdown-content overflow-hidden transition-max-height duration-300 ease-in-out"
        style={{ maxHeight: '0px' }}
      >
        {children}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #3c444c;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;

  .booking-status {
    color: #f27a54;
  }

  .line-height-reset {
    line-height: normal;
  }

  .info-separator {
    border-bottom: 1px solid #3c444c;
  }

  .user-avatar {
    object-fit: cover;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  
  .dropdown-info-wrapper p {
    margin-right: 2rem;
  }

  .dropdown-content {
    background-color: #292F35;
  }

  .section {
    border-bottom: 1px solid #3c444c;
  }

  svg {
    stroke: #CBCFD4;
  }

  .pill {
    padding: .25rem .75rem;
    background-color: #30363d;
    border-radius: 7px;
  }

  .pill p {
    font-size: 12px;
  }
`

export default Dropdown;
