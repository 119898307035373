import React, {useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { storage } from '../../Services/firebase/FirebaseConfig';
import { useParams } from 'react-router-dom';
import IconStar from '../Icons/IconStar';
// import useTextLimit from '../../Hooks/useTextLimit';

interface AlbumCardProps {
  photoCount?: number,
  album_owner_username: string,
  bannerImg?: string,
  album_default_banner: string,
  session_date: any,
  album_name: string,
  album_upload_date: Date,
  album_description: string,
  albumSessionDate: string,
  album_owner_id: string,
  album_media_count: number,
  album_spot: string,
  albumKey: any,
  item: any,
  username: string,
  owner: string,
  user_id: string,
  mediaPrice: string,
  flmr_hourly_rate: number,
  flmr_listing_banner_url: any,
  flmr_media_type: any,
  profile_banner_image: string
}

function PhotographerCard(props: AlbumCardProps) {
  const [isVideo, setIsVideo] = React.useState(false);
  const [userAvatar, setUserAvatar] = useState('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const { continent, country, state, county, surfspot }: any = useParams();
  const url = `/flmr/${props.username}/${props.user_id}/${continent}/${country}/${state}${county ? `/${county}` : ''}/${surfspot}`;
  // const firestoredb = getFirestore();

  // Get users profile image
  useEffect(() => {
    const storageRef = storage; 
    getDownloadURL(sRef(storageRef, `user_images/${props.user_id}/avatar/user_avatar.png`))
        .then((url) => {
          setUserAvatar(url);
        })
        .catch((error) => {
          console.log("Album Card Avatar Error: ", error);
        });
  }, [props.album_name]);

  function handleError() {
    setIsVideo(true);
  }

  // render first second of video to grab thumbnail
  useEffect(() => {
    if (isVideo && videoRef.current) {
      const video = videoRef.current;

      // Set up event listeners to seek to the 1-second mark
      video.addEventListener('loadedmetadata', () => {
        video.currentTime = 1; // Seek to the first second
      });

      video.addEventListener('canplay', () => {
        video.pause(); // Pause video after the first second is loaded
      });

      // Load the video
      video.load();

      // Cleanup listeners when the component unmounts
      return () => {
        video.removeEventListener('loadedmetadata', () => {});
        video.removeEventListener('canplay', () => {});
      };
    }
  }, [isVideo]);

  return (
    <Wrapper className='shadow-md'>
      {isVideo ? (
        <video
          ref={videoRef}
          controls
          muted
          className="object-cover"
          src={props.flmr_listing_banner_url}
          onError={handleError}
          style={{ width: '100%' }}
          preload="none"
        />
      ) : (
        <BannerImg
          onError={handleError}
          src={props.flmr_listing_banner_url}
        />
      )}
      <ContentWrapper>
        <Link to={`/flmr/${props.username}/${props.user_id}/${continent}/${country}/${state}${county ? `/${county}` : ''}/${surfspot}`}>
          <UserImg className="mb-3 w-12 h-12 rounded-full shadow-lg" src={userAvatar} alt=""/>
        </Link>
        <div className='p-6 info-separator'>
        <h2 className="mb-2">{props.username}</h2>
          <div className='flex mt-2'>
            <IconStar /><p className="ml-2">No Ratings Yet</p> 
          </div>
        </div>
        
        <div className="flex px-6 py-4 info-separator">
          {props.flmr_media_type.map((item: any) => {
            return (
              <MediaTypeChip>
                <p>{item}</p>
              </MediaTypeChip>
          )})}
        </div>
        <p className="text-xl px-6 pt-4 pb-0"><b>${props.flmr_hourly_rate}</b> / Hour</p>
      </ContentWrapper>
      <div className="px-6">
        <Link to={url}>
          <ViewProfileBtn className="btn-profile">HIRE FLMR</ViewProfileBtn>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: #3C444C;
  border-radius: 4px;
  border: 1px solid #30363d;

  video {
    max-height: 176px;
  }

  .info-separator {
    border-bottom: 1px solid #30363D;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.25rem;
    color: #CBCFD4;
  }

  h4 {
    color: #F27A54;
    font-size: 12px;
    text-transform: uppercase;
  }

  h5 {
    margin-bottom: .25rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: white;
  }

`

const MediaTypeChip = styled.div`
  background-color: #30363d;
  padding: 2px 1rem;
  border-radius: 25px;
  margin-right: .5rem;
  p {
    color: #CBCFD4;
    font-size: 10px;
  }
`

const UserImg = styled.img`
  position: absolute;
  object-fit: cover;
  background-color: #3c444c;
  padding: 2px;
  right: 45px;
  top: 151px;
`

const BannerImg = styled.img`
  object-fit: cover;
  height: 175px;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`

const ViewProfileBtn = styled.button`
    width: 100%;
    height: 35px;
    margin: 1.5rem 0rem;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border-radius: 7px;
    background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
`


export default PhotographerCard