import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import iconAccountCircle from '../../Images/account_circle.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserAuth } from '../../Context/UserAuthContext';
import { FileUploader } from "react-drag-drop-files";
import { uploadBytesResumable, getStorage, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { Link } from 'react-router-dom';
import BookingRequestCard from '../BookingRequestCard/BookingRequestCard';
import IconEdit from '../Icons/IconEdit';
import { PendingBookingReqs } from '../../Services/firebase/PendingBookingReqs';
import { FlmrBookingReqs } from '../../Services/firebase/FlmrBookingReqs';
import { ToggleFlmrActiveStatus } from '../../Services/firebase/ToggleFlmrActiveStatus';
import ToggleButton from '../ToggleButton/ToggleButton';
import SpotChip from '../SpotChip/SpotChip';
import IconStar from '../Icons/IconStar';

function AuthedUserProfile() {
  let { userProfile, user } = useUserAuth();
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState();
  const [myBookingRequests, setMyBookingRequests] = useState([]);
  const [flmrBookingReqs, setFlmrBookingRequests] = useState([]);
  const [flmrIsActive, setFlmrIsActive] = useState();
  const [activeTab, setActiveTab] = useState('profile'); // Track the active tab
  const storage = getStorage();
  let profileImgUrl;

  // Get users profile image from storage
  useEffect(() => {
    if (userProfile) {
      const profileImgRef = sRef(storage, `user_images/${user.uid}/avatar/user_avatar.png`);
      getDownloadURL(profileImgRef)
        .then((url) => {
          setAvatar(url);
        })
        .catch((error) => {
          console.error("Error fetching avatar URL:", error);
        });
    }
  }, [userProfile]);

  // Handle choosing + uploading new avatar image
  async function handleChooseFiles(selectedFile) {
    const renamedFile = new File([selectedFile], 'user_avatar.png', { type: 'image/png' });
    setFile(renamedFile);

    const avatarStorageRef = sRef(storage, `user_images/${user.uid}/avatar/user_avatar.png`);
    
    try {
      const uploadTask = uploadBytesResumable(avatarStorageRef, renamedFile);

      uploadTask.on('state_changed',
        (snapshot) => {
          // You can track the progress of the upload here if needed
        },
        (error) => {
          console.error('Error uploading file:', error);
        },
        () => {
          toast.success('Profile picture updated', {
            theme: 'dark'
          });
          setAvatar(profileImgUrl);
        },
      );
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  // Get users booking requests thats they made
  useEffect(() => {
    async function fetchBookingRequests() {
      if (user) {
        const requests = await PendingBookingReqs(user);
        setMyBookingRequests(requests);
      }
    }
    fetchBookingRequests();
  }, [user]);

  // Get job requests submitted by surfers
  useEffect(() => {
    async function fetchJobRequests() {
      if (user) {
        const requests = await FlmrBookingReqs(user);
        setFlmrBookingRequests(requests);
      }
    }
    fetchJobRequests();
  }, [user]);

  // Handle tab selection
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Get users current active listing state
  useEffect(() => {
    setFlmrIsActive(userProfile.flmr_available);
  }, [user, userProfile]);

  // Flmr Available Toggle
  const handleToggle = async (value) => {
    try {
      await ToggleFlmrActiveStatus(user, value);
    } catch (error) {
      console.error("Error toggling status: ", error);
      toast.error("Failed to update booking status.", { theme: "dark" });
    }
  };

  console.log(userProfile);
  
  return (
    <ProfilePage className="w-full mt-6 px-6">
      {/* Profile Intro */}
      <ProfileIntro className="shadow-md">
        <div className="banner">
          <ProfileImageWrapper>
            <label>
              {avatar ? (
                <img src={avatar} className="shadow-md" alt="Profile Picture" />
              ) : (
                <img src={file ? URL.createObjectURL(file) : iconAccountCircle} className="shadow-lg" alt="Profile Picture" />
              )}
              <div className="btn-message">
                <FileUploader
                  handleChange={handleChooseFiles}
                  name="file"
                  required
                >
                  <IconEdit />
                </FileUploader>
              </div>
            </label>
          </ProfileImageWrapper>
        </div>

        <div className="px-8 mt-28 grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="text-left">
          <h2>{userProfile?.username}</h2>
          <p>{userProfile?.email}</p>
          <div className="flex my-2">
            <IconStar />
            <p className="pl-2">
              {userProfile && userProfile.user_rating ? userProfile.user_rating : 'No Rating Yet'}
            </p>
          </div>
        </div>
        {userProfile.is_flmr 
        ?
        <div className="flex flex-col items-start md:items-end">
          <div className="btn-wrapper flex-col items-start md:items-end">
            <h4>Active Status</h4>
            <ToggleButton onToggle={handleToggle} defaultToggled={flmrIsActive}/>
            <p>{flmrIsActive ? "Accepting Bookings" : "Not Accepting Bookings"}</p>
          </div>
        </div> 
        :
        null      
        }
      </div>
      </ProfileIntro>

      <div className="flex mt-4 overflow-x-auto overflow-y-hidden whitespace-nowrap dark:border-gray-700 bg-[#3c444d]">
        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'profile' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('profile')}
        >
          Overview
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'listingSettings' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('listingSettings')}
        >
          Listing Settings
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'notification' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('notification')}
        >
          History
        </button>

        <button
          className={`inline-flex items-center px-4 -mb-px text-sm text-center xs:text-base whitespace-nowrap focus:outline-none h-[50px] ${
            activeTab === 'payment' ? 'text-[#CBCFD4] border-b-2 border-blue-500 border-[#F27A54]' : 'text-white border-transparent dark:text-white hover:border-gray-400'
          }`}
          onClick={() => handleTabChange('payment')}
        >
          Payment
        </button>
      </div>

      {/* Conditionally Render Content Based on Active Tab */}
      <div className="mb-4">
        {/* Overview */}
        {activeTab === 'profile' && (
          <div className="no-request-wrapper">
            <div className="separator p-4 py-6">
              <h2 className="text-[#CBCFD4] text-md">Username</h2>
              <p className="text-[#CBCFD4]">{userProfile.username}</p>
            </div>
            <div className="px-4 py-6">
              <h2 className="text-[#CBCFD4] text-md">Email</h2>
              <p className=" text-[#CBCFD4]">{user.email}</p>
            </div>
          </div>
        )}

        {/* Listing Settings */}
        {activeTab === 'listingSettings' && (
          userProfile.is_flmr ? (
            <div className="no-request-wrapper">
              {/* HOURLY RATE */}
              <div className="separator p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md">Hourly Rate</h2>
                <p className="text-[#CBCFD4]">${userProfile.flmr_hourly_rate} / Hour</p>
              </div>
              {/* MEDIA TYPES */}
              <div className="separator p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md mb-2">Selected Media Types</h2>
                <div className="flex">
                  {userProfile.flmr_media_type.map((data, index) => (
                    <div className="media-type" key={index}>
                      <p className="py-1 px-4">{data}</p>
                    </div>
                  ))}
                </div>
              </div>
              {/* SURF SPOTS */}
              <div className="separator p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md mb-2">Selected Surf Spots</h2>
                <div className="flex flex-wrap">
                  {userProfile.flmr_locations.map((data, index) => (
                    <SpotChip key={index} spotName={data} className="w-auto mr-[5px] mb-[5px]" />
                  ))}
                </div>
              </div>
              {/* BIO */}
              <div className="p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md mb-2">Listing Bio</h2>
                <div className="flex flex-wrap">
                  <p>{userProfile.flmr_about}</p>
                </div>
              </div>
              <SectionFooter>
                <button disabled className="btn-cancel font-medium rounded-sm text-sm px-5 py-1.5 text-center mr-4">Cancel</button>
                <button disabled className="btn-save font-medium rounded-sm text-sm px-5 py-1.5 text-center mr-4">Save</button>
              </SectionFooter>
            </div>
          ) : (
            <div className="no-request-wrapper">
              <div className="p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md">Listing Settings</h2>
                <p className="text-[#CBCFD4]">You have not applied to be listed on FLMR</p>
                <p className="text-[#CBCFD4]">
                  <Link to={`/onboarding-start/${user.uid}`}>
                    <span className="text-[#F27A54]">Sign up here </span>
                  </Link> 
                  to start accepting bookings.
                </p>
              </div>
            </div>
          )
        )}


        {/* History */}
        {activeTab === 'notification' && (
          <div> 
            <div className="no-request-wrapper">
              <div className="p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md">You don't have any purchase history.</h2>
                <p className="text-[#CBCFD4]">Start booking sessions!</p>
              </div>
            </div>
          </div>
        )}

        {/* Payment */}
        {activeTab === 'payment' && (
          <div>
            <div className="no-request-wrapper">
              <div className="p-4 py-6">
                <h2 className="text-[#CBCFD4] text-md">Available Funds</h2>
                <p className="text-[#CBCFD4]">You haven't completed any bookings.</p>
              </div>
            </div>
          </div>
        )}

      </div>

      {/* <Section className="shadow-md mt-4 mb-4">
        <h2 className="pt-6">Get Paid</h2>
        <div className="flex justify-between items-center">
          <p>Available Balance: $0.00</p>
          <p></p>
          <PrimaryButton onClick={handleWithdraw} className="transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300 justify-between">Withdraw Funds</PrimaryButton>
        </div>
      </Section> */}
      
    </ProfilePage>
  );
}

const ProfileImageWrapper = styled.div`
  display: flex;
  height: 175px;

  input {
    width: 100%;
  }

  img {
    position: relative;
    cursor: pointer;
    max-width: 148px;
    max-height: 148px;
    min-width: 148px;
    min-height: 148px;
    object-fit: cover;
    bottom: -105px;
    left: 2rem;
    background-color: #3c444c;
    height: 148px;
    border-radius: 50%;
    padding: 6px;
  }

`

const ProfileIntro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3c444c;
  border-radius: 4px;

  h2 {
    color: #CBCFD4;
  }

  .btn-message {
    font-family: 'Montserrat';
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    background-color: #30363d;
    color: #CBCFD4;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 40px;
    top: 300px;
  }

  .btn-message button {
    font-size: 12px;
  }

  .btn-message label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-message:hover {
    background-color: #272a2e;
    svg path {
      fill: #F27A54;
    }
  }

  .btn-message:active {
    background-color: #1c2126;
    border-color: #1c2126;
    color: white;
  }

  .btn-message:focus {
      outline: none;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  

  .btn-wrapper {
    display: flex;
  }
`

const ProfilePage = styled.div`

  // This is hacky and we shouldn't do this, but lets just get it done

  .no-request-wrapper {
    border: 1px solid #3c444c;
  }

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d6165;
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5d6165;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #5d6165;
  }

  input {
    font-size: 12px;
    color: #CBCFD4;
  }

  .banner {
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(90deg, rgb(242, 122, 84) 0%, rgb(161, 84, 242) 186.42%);
  }

  .userPhoto {
    background-color: gray;
    border-radius: 50%;
  }

  .borderCustom {
    border-bottom: 1px solid #30363d;
  }

  .media-type {
    border: 1px solid #3c444d;
    border-radius: 3px;
    margin-right: 1rem;
  }

  .separator {
    border-bottom: 1px solid #3c444d;
  }
`

const SectionFooter = styled.div`
  height: 50px;
  background-color: #3c444c;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-cancel {
    background-color: #30363e;
    font-size: 10px;
    color: #cbcfd4;
    /* Disabled look */
  }

  .btn-cancel:disabled {
    background-color: #20242b;
    color: #8a8e94;
    opacity: 0.6; 
  }

  .btn-save {
    background: linear-gradient(90deg, #F27A54 0%, #A154F2 100%);
    color: white;
    font-size: 10px;
    /* Disabled look */
  }

  .btn-save:disabled {
    background: linear-gradient(90deg, #b35a3c 0%, #7540b3 100%);
    color: #e0e0e0;
    opacity: 0.6;
  }

`

export default AuthedUserProfile;
