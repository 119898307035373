import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PhotoCard from '../../Components/PhotoCard/PhotoCard';
import { listAll, getDownloadURL  } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { storage } from '../../Services/firebase/FirebaseConfig';
import { useParams } from 'react-router-dom';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';

interface AlbumProps {
  user: any,
  owner: string,
  album_spot: string,
  album_name: string,
  downloadUrl: any,
  imageName: any,
}

function Album(props: AlbumProps) {

  // const { state } = useLocation();
  const db = getFirestore();

  const [imgName, setImgName] = useState('');
  const [imageList, setImageList] = useState([]);
  const [imageListThumbnails, setImageListThumbnails] = useState([]);
  const [currentAlbum, setCurrentAlbum] = useState<any>(null);
  const [itemsToShow, setItemsToShow] = useState(16);
  const { id, albumspot, albumname } = useParams<{ id: string, albumspot: string, albumname: string }>();
  const [renderedImages, setRenderedImages] = useState([]);

  const albumRefThumbnails = '/albums/' + id + '/' + albumspot + '/' + albumname + '/thumbnails';
  const albumRefFullSize = '/albums/' + id + '/' + albumspot + '/' + albumname;

  // Need to grab album that === albumname
  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "albums"), where("album_name", "==", albumname));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setCurrentAlbum(doc.data());
      });
    };
    fetchData();
  }, []);
 
  // Get a list of images, idea is to have Link component pass the info needed to populate the fileUploadRef from AlbumCard component
  // state.albumLink example
  // const fileUploadRef = sRef(storage, `albums/GOTzQ3ygGsbFA903pVoJBFp54uJ2/2nd Beach (Sachuest Beach)/APS SICK SHOTS DOOD/`);
  useEffect(() => {
    const fileUploadRef = sRef(storage, albumRefThumbnails);
    listAll(fileUploadRef)
      .then((res) => {
        const downloadPromises = res.items.map((itemRef) => {
          return getDownloadURL(sRef(storage, itemRef.fullPath));
        });
        Promise.all(downloadPromises)
          .then((urls) => {
            if (currentAlbum?.album_media_price > 0) {
              const watermarkedUrls = urls.filter((url) => url.includes("_watermarked"));
              setImageListThumbnails(watermarkedUrls);
            } else if (currentAlbum?.album_media_price === 0) {
              const originalUrls = urls.filter((url) => !url.includes("_watermarked"));
              setImageListThumbnails(originalUrls);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error, "storage listing error");
      });
  }, [currentAlbum]);  

  // Getting full size image urls as well
  useEffect(() => {
    const fileUploadRef = sRef(storage, albumRefFullSize);
    listAll(fileUploadRef)
      .then((res) => {
        const downloadPromises = res.items.map((itemRef) => {
          return getDownloadURL(sRef(storage, itemRef.fullPath));
        });
        Promise.all(downloadPromises)
          .then((urls) => {
            if (currentAlbum?.album_media_price > 0) {
              const watermarkedUrls = urls.filter((url) => url.includes("_watermarked"));
              setImageList(watermarkedUrls);
            } else if (currentAlbum?.album_media_price === 0) {
              const originalUrls = urls.filter((url) => !url.includes("_watermarked"));
              setImageList(originalUrls);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error, "storage listing error");
      });
  }, [currentAlbum]); 

  useEffect(() => {
    // Map through uploaded photos
    let imagesToRender;
  
    if (imageListThumbnails.length === 0) {
      // If imageListThumbnails is empty, use imageList directly
      imagesToRender = imageList.map((fullSizeUrl, index) => {
        return (
          <PhotoCard
            key={Math.random()}
            photographUrl={fullSizeUrl}
            owner={currentAlbum.album_owner_username}
            fullSizeUrl={fullSizeUrl}
            albumName={albumname}
            imageName={imgName}
            albumSpot={albumspot}
            photographerName={currentAlbum.album_owner_username}
            photographDate={currentAlbum.session_date}
            mediaPrice={currentAlbum.album_media_price}
          />
        );
      });
    } else {
      // Otherwise, use imageListThumbnails
      imagesToRender = imageListThumbnails
        .slice(0, itemsToShow) // Display only the first 'itemsToShow' items
        .map((thumbnail, index) => {
          const fullSizeUrl = imageList[index]; // Get the corresponding full-size URL
          if (fullSizeUrl) {
            return (
              <PhotoCard
                key={Math.random()}
                photographUrl={thumbnail}
                owner={currentAlbum.album_owner_username}
                fullSizeUrl={fullSizeUrl}
                albumName={albumname}
                imageName={imgName}
                albumSpot={albumspot}
                photographerName={currentAlbum.album_owner_username}
                photographDate={currentAlbum.session_date}
                mediaPrice={currentAlbum.album_media_price}
              />
            );
          }
          return null; // Handle cases where fullSizeUrl is undefined
        });
    }
  
    setRenderedImages(imagesToRender);
  }, [imageListThumbnails, imageList, itemsToShow, currentAlbum]);

   // Function to load more items
   const loadMoreItems = () => {
    setItemsToShow(prevItemsToShow => prevItemsToShow + 20);
  };

  return (
    <AlbumWrapper>
      <AlbumInfoWrapper>
        <div>
          <h1 className="mb-2">{albumname}</h1>
          <p><b>Photographer: </b>{currentAlbum?.album_owner_username}</p>
          <p><b>Album Spot: </b>{albumspot}</p>
        </div>
      </AlbumInfoWrapper>
      <PhotoSectionWrapper>
        <div className="grid gap-4 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          {renderedImages}
        </div> 
        {itemsToShow < imageListThumbnails.length && (
          <div className="flex mt-4 justify-center">
            <LoadMoreBtn className="shadow-md" onClick={loadMoreItems}>Load More</LoadMoreBtn>
          </div>
        )}
      </PhotoSectionWrapper>
    </AlbumWrapper>
  )
}

const AlbumWrapper = styled.div`
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: 3px;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    font-size: 24px;
    font-weight: 900;
  }
`

const AlbumInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #3d444c;
  padding: 1rem 1.5rem;
  border-radius: 4px;

  p {
    line-height: 20px;
  }
`

const LoadMoreBtn = styled.button`
  width: 125px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 5px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
  :hover {
    background: linear-gradient(90deg,#f27954c4 0%,#a054f2ba 100%);
  }
`

const PhotoSectionWrapper = styled.div`
  margin-top: 1rem;
`

export default Album