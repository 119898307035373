import React from 'react';
import styled from 'styled-components';

function PrimaryButton({ children, className, onClick, disabled = false }) {
  return (
    <ButtonWrapper className={className}>
      <button className="button-2" onClick={onClick} disabled={disabled}>
        {children}
      </button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  .button-2 {
    width: 150px;
    height: 35px;
    font-size: 10px;
    font-weight: 700;
    color: white;
    border-radius: 7px;
    letter-spacing: 1px;
    background-image: -webkit-linear-gradient(90deg, rgb(242, 122, 84) 100%, rgb(161, 84, 242) 186.42%);
    background-image: linear-gradient(90deg, rgb(242, 122, 84) 0%, rgb(161, 84, 242) 186.42%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 100%;
    -webkit-transition: background 250ms ease-in-out;
    transition: background 250ms ease-in-out;
  }

  button:disabled {
    background-image: none;
    background-color: gray;
    color: black;
  }
`


export default PrimaryButton