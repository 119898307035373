import React, { useState } from 'react';

function IconDownload() {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const fill = hovered ? '#F27A54' : '#31373E';

  const transition = 'fill 0.2s ease-out';

  const svgStyle = {
    transition
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
      <svg width="25" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <style>
          {`
            @keyframes bounce {
              0% {
                transform: scale(1);
              }
              25% {
                transform: scale(0.9);
              }
              50% {
                transform: scale(1.1);
              }
              75% {
                transform: scale(0.95);
              }
              100% {
                transform: scale(1);
              }
            }
          `}
        </style>
      <path 
         d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
        fill={fill}
        style={svgStyle}/>
      </svg>
    </div>
  );
}

export default IconDownload;