import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { toast } from 'react-toastify';

interface FlmrBookingReqs {
  document_id: string; // Add an ID field to the interface
  [key: string]: any; // Allow any other properties
}

export async function FlmrBookingReqs(user: any): Promise<FlmrBookingReqs[]> {
  const db = getFirestore();

  try {
    const bookingReqsCollectionRef = collection(db, "bookings");
    // const q = query(bookingReqsCollectionRef, where("flmr_id", "==", user.uid));
    // Create a query that filters for documents where booking_status is 'pending'
    const q = query(
      bookingReqsCollectionRef,
      where("flmr_id", "==", user.uid),
      where("booking_status", "==", "pending") // Filter for 'pending' status
    );
    const querySnapshot = await getDocs(q);

    // Map the documents to include their IDs
    const bookingReqs = querySnapshot.docs.map((doc) => ({
      document_id: doc.id, // Include the document ID
      ...doc.data(), // Include the document data
    }));

    return bookingReqs;
  } catch (error) {
    console.error("Error fetching booking requests:", error);
    toast.error("Failed to fetch booking requests", {
      theme: "dark",
    });
    return [];
  }
}
