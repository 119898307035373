import React from 'react'
import styled from 'styled-components';
import IconInstagram from '../../Components/Icons/IconInstagram';

function About() {

  return (
    <ContactWrapper>
      <InnerWrapper>
      <IntroTextWrapper>
        <FancyText>About Us</FancyText>
        <p>Currently a one man team, a life long surfer trying to connect surfers and the ones who film and photograph the sessions. Our goal is to give photographers an outlet to share their work, and maybe have it purchased by someone they got a picture of surfing, or just give it away if they would rather do that. We believe in high quality photos and video, not compressed and pixelated like other places you might post these images. We want to get sick content into peoples hands and maybe a few dollars to the photographers and videographers putting in the work. At the end of the day, we just like to surf and want to relive the best days waves we get and have the original, full HD shot.</p>
        <SocialMediaWrapper>
          <a href="https://www.instagram.com/flmr.app/" target="_blank"><IconInstagram /></a>
        </SocialMediaWrapper>
      </IntroTextWrapper>
      </InnerWrapper>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  
  margin-top: 1rem;
  border-radius: 3px;
`

const IntroTextWrapper = styled.div`

`
const InnerWrapper = styled.div`
  max-width: 960px;
  background-color: #3C444C;
  padding: 2rem;
  margin: 1rem;
`

const FancyText = styled.h2`
  font-family: 'Montserrat', sans-serif;
  padding: 1rem 0rem;
  font-size: 50px;
  line-height: 70px;
  background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;

  img {
    width: 50px;
    padding-right: 15px;
  }
`

export default About