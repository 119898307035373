import React from 'react'
import styled from 'styled-components' 

function SignUpValidate() {
  return (
    <Wrapper>
      <TextWidthWrapper>
        <h1>THANKS FOR JOINING FLMR</h1>
        <p className='mt-6'>Check your email for the verification link to jump right into it. Sometimes emails can be weird so check your spam folder if you don't see the verification email. It may also take a minute or two to show up.</p>
        <p className='mt-6'>Still don’t see a link? <span>Resend verification email</span></p>
      </TextWidthWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  display: flex;
  overflow: hidden;
  flex-direction: column;
  margin: 1rem;
  background-color: #3C444C;
  padding: 2rem;
  border-radius: 3px;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    font-size: 24px;
    font-weight: 900;
  }
`

const TextWidthWrapper = styled.div`
  margin: 0 auto;
  max-width: 760px;
  text-align: center;

  img {
    width: 105px;
  }

  span {
    color: #F27A54;
  }
`

export default SignUpValidate