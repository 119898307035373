import React from 'react'
import styled from 'styled-components';
import faqs from './faqs.json';
import FAQ from '../../Components/FAQ/FAQ';
import IconJellyfish from '../../Components/Icons/IconJellyfish';

function Faqs() {

  return (
    <>
    <FAQWrapper>
      <InnerWrapper>
        <HeaderWrapper>
          <FancyText>Got questions?</FancyText>
          <IconJellyfish />
        </HeaderWrapper>
        <p>Have a question that we didn't cover here? Reach out any time on our <a href="/contact"><b>contact page</b></a>.</p>
        <div className="relative w-full pt-10">
          <div className="mx-auto">
              <div className="mx-auto grid">
              { faqs.map((item, index) => {
                return <FAQ key={index} question={item.question} answer={item.answer}/>
              })}
              </div>
          </div>
      </div>   
      </InnerWrapper>
    </FAQWrapper>
    </>
  )
}

const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  border-radius: 5px;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 525px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`

const InnerWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 2rem;
  margin: 1rem;
`

const FancyText = styled.h2`
  font-family: 'Montserrat', sans-serif;
  padding: 1rem 0rem;
  font-size: 50px;
  background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 525px) {
    font-size: 36px;
  }
`

export default Faqs