import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

interface FlmrCompleteBooking {
  // You can define specific properties if needed
}

export async function FlmrCompleteBooking(document_id: string): Promise<void> {
  const db = getFirestore();

  try {
    // Reference to the document in the 'bookings' collection
    const bookingDocRef = doc(db, "bookings", document_id);

    // Update the booking_status field
    await updateDoc(bookingDocRef, {
      booking_status: 'completed'
    });

    toast.success("Booking Completed", {
      theme: "dark",
    });
  } catch (error) {
    console.error("Error completing booking:", error);
    toast.error("Failed to complete booking", {
      theme: "dark",
    });
  }
}
