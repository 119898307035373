import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { toast } from 'react-toastify';

interface InProgressBookings {
  document_id: string;
  [key: string]: any;
}

export async function InProgressBookings(user: any): Promise<InProgressBookings[]> {
  const db = getFirestore();
  
  try {
    const bookingReqsCollectionRef = collection(db, "bookings");
    
    // Query for documents where booking_user_id == user.uid
    const q1 = query(
      bookingReqsCollectionRef,
      where("booking_user_id", "==", user.uid),
      where("booking_status", "==", "accepted_in_progress") // Filter for 'accepted_in_progress' status
    );

    // Query for documents where flmr_id == user.uid
    const q2 = query(
      bookingReqsCollectionRef,
      where("flmr_id", "==", user.uid),
      where("booking_status", "==", "accepted_in_progress") // Filter for 'accepted_in_progress' status
    );

    // Get documents from both queries
    const [snapshot1, snapshot2] = await Promise.all([getDocs(q1), getDocs(q2)]);

    // Combine the results and remove duplicates
    const bookingReqsMap = new Map<string, InProgressBookings>();
    snapshot1.docs.forEach((doc) => {
      bookingReqsMap.set(doc.id, { document_id: doc.id, ...doc.data() });
    });
    snapshot2.docs.forEach((doc) => {
      bookingReqsMap.set(doc.id, { document_id: doc.id, ...doc.data() });
    });

    const bookingReqs = Array.from(bookingReqsMap.values());

    return bookingReqs;
  } catch (error) {
    console.error("Error fetching in progress bookings:", error);
    toast.error("Failed to fetch in progress bookings", {
      theme: "dark",
    });
    return [];
  }
}
