import { getStorage, uploadBytes } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import UserAvatar from '../../../../Images/user_avatar.png'

export function AddUserAvatar(uid: any) {
    const storage = getStorage();
    const avatarStorageRef = sRef(storage, `user_images/${uid}/avatar/user_avatar.png`);
    
    const metadata = {
      contentType: 'image/png', // Set the content type to "image/png"
    };
    
    fetch(UserAvatar)
      .then(response => response.blob())
      .then(blob => {
        uploadBytes(avatarStorageRef, blob, metadata).then((snapshot) => {
          // console.log("Avatar created");
        });
      })
      .catch(error => {
        console.error('Error fetching or converting the image:', error);
      });
}
