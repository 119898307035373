import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AlbumCardPhotoCount from '../AlbumCardPhotoCount/AlbumCardPhotoCount';
import { getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { storage } from '../../Services/firebase/FirebaseConfig';
import useTextLimit from '../../Hooks/useTextLimit';

interface AlbumCardProps {
  photoCount?: number,
  album_owner_username: string,
  bannerImg?: string,
  album_default_banner: string,
  session_date: any,
  album_name: string,
  album_upload_date: Date,
  album_description: string,
  albumSessionDate: string,
  album_owner_id: string,
  album_media_count: number,
  album_spot: string,
  albumKey: any,
  item: any,
  owner: string
  mediaPrice: string
}

function AlbumCard(props: AlbumCardProps) {
  const [isVideo, setIsVideo] = React.useState(false);
  const [userAvatar, setUserAvatar] = useState('');
  // const firestoredb = getFirestore();

  const truncatedDescription = useTextLimit({ text: props.album_description, elementType: 'p',maxLength: 92 });
  const truncatedAlbumName = useTextLimit({ text: props.album_name, elementType: 'h5',maxLength: 70 });
  
  // Get users profile image
  useEffect(() => {
    const storageRef = storage; 
    getDownloadURL(sRef(storageRef, `user_images/${props.album_owner_id}/avatar/user_avatar.png`))
        .then((url) => {
          setUserAvatar(url);
        })
        .catch((error) => {
          console.log("Album Card Avatar Error: ", error);
        });
  }, [props.album_name]);

  function handleError() {
    setIsVideo(true);
  }

  // TODO: Check to see if this is needed still?
  useEffect(() => {
    
  }, [props.album_default_banner]);

  return (
    <Wrapper className='shadow-lg'>
      <AlbumCardPhotoCount mediaCount={props.album_media_count} />
      {isVideo ? (
        <video
          controls
          muted
          className="object-cover"
          src={props.album_default_banner}
          onError={handleError}
          style={{ width: '100%' }}
        />
      ) : (
        <BannerImg
          onError={handleError}
          src={props.album_default_banner}
        />
      )}
      <ContentWrapper>
        <Link to={`/profile/${props.album_owner_username}/${props.album_owner_id}`}>
          <UserImg className="mb-3 w-12 h-12 rounded-full shadow-lg" src={userAvatar} alt=""/>
        </Link>
        <h4 style={{padding: "10px 0px"}}>
          <Link to={`/profile/${props.album_owner_username}/${props.album_owner_id}`}>{props.album_owner_username}</Link>
        </h4>
        {truncatedAlbumName}
        {/* <h5 className="mb-1 text-xl font-medium text-white">{truncatedAlbumName}</h5> */}
        <h4 style={{padding: "10px 0px"}}>{props.album_spot}</h4>
        <p><b>Session Date: </b>{props.session_date}</p>
        {truncatedDescription} {/* props.albumDescription */}
        
      </ContentWrapper>
      <div className="px-6" style={{marginTop: 'auto'}}>
        <Link to={`/albums/${props.album_owner_id}/${props.album_spot}/${props.album_name}`}>
          <ViewProfileBtn className="btn-profile">VIEW ALBUM</ViewProfileBtn>
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: #3C444C;
  border-radius: 4px;
  border: 1px solid #30363d;

  video {
    max-height: 176px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 0rem 1.5rem;

  h4 {
    color: #F27A54;
    font-size: 12px;
    text-transform: uppercase;
  }

  h5 {
    margin-bottom: .25rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: white;
  }

`

const UserImg = styled.img`
  position: absolute;
  object-fit: cover;
  background-color: #3c444c;
  padding: 2px;
  right: 45px;
  top: 151px;
`

const BannerImg = styled.img`
  object-fit: cover;
  height: 175px;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
`

const ViewProfileBtn = styled.button`
    width: 100%;
    height: 35px;
    margin: 1.5rem 0rem;
    font-size: 10px;
    color: white;
    font-weight: 700;
    border-radius: 7px;
    background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
`


export default AlbumCard