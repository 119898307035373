import { getFirestore, addDoc, collection, doc } from "firebase/firestore";
import { toast } from 'react-toastify';

export async function AddToCart (user: any, props: any) {

    // If no user, display error
    if (!user) {
      toast.error("Please log in to purchase..", {
        theme: "dark"
      });
      return;
    }

    const db = getFirestore();
    const cartRef = doc(db, "carts", user.uid);
  
    const itemData = {
      dateAddedToCart: Date(),
      albumName: props.albumName,
      imageURL: props.photographUrl,
      imageName: props.imageName,
      imagePrice: props.mediaPrice,
      owner: props.owner,
      photographerName: props.photographerName,
      photoGraphDate: props.photographDate
    };
  
    // Add the item to the user's cart
    // await setDoc(cartRef, { item1: itemData }, { merge: true });
    await addDoc(collection(cartRef, "items"), itemData);

    toast.success("Added to cart", {
      theme: "dark"
    });
  }
