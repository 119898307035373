import React, { useState, useEffect } from 'react';

interface TextLimitHookProps {
  text: string;
  maxLength: number;
  elementType: 'p' | 'h1' | 'h5';
}

const useTextLimit = ({ text, maxLength, elementType }: TextLimitHookProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedText, setTruncatedText] = useState('');

  useEffect(() => {
    if (text.length > maxLength) {
      setIsTruncated(true);
      setTruncatedText(text.slice(0, maxLength) + '...');
    } else {
      setIsTruncated(false);
      setTruncatedText(text);
    }
  }, [text, maxLength]);

  const Element = elementType;

  return <Element>{truncatedText}</Element>;
};

export default useTextLimit;
