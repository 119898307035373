import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserAuth } from '../../Context/UserAuthContext';
import { collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';

interface CartCounterProps {
  
}

function CartCounter(props: CartCounterProps) {
  const db = getFirestore();
  let { user } = useUserAuth();  
  const [cartItems, setCartItems] = useState([]);

  // Get amount of items in the users cart from firestore - keep it in sync
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (user && user.uid) {
          const cartItemsRef = collection(db, 'carts', user.uid, 'items');
          const unsubscribe = onSnapshot(cartItemsRef, (querySnapshot) => {
            const itemsData = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setCartItems(itemsData);
          });
          // Return a cleanup function to unsubscribe from the snapshot listener
          return () => unsubscribe();
        }
      } catch (error) {
        toast.error("Added to cart", {
          theme: "dark"
        });
      }
    };

    fetchCartItems();
  }, [db, user]);

  return (
    <>
      { cartItems.length > 0 
        ? <Wrapper><p>{cartItems.length}</p></Wrapper> 
        : null
      }
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  background-color: #F27A54;
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #3c444c;
  right: 70px;
  top: 25px;

  p {
    color: #30363d;
    font-size: 10px;
    font-weight: 900;
  }
`

export default CartCounter