import React from 'react'
import styled from 'styled-components';
import ContactForm from '../../Components/ContactForm/ContactForm';

function Contact() {

  return (
    <ContactWrapper>
      <InnerWrapper>
      <IntroTextWrapper>
        <FancyText>Contact Us</FancyText>
        <p className="text-white pb-8">Contact us any time for any concerns, requests, just say hey. Anything really - we will respond. Or feel free to send us a DM on Instagram, we're super responsive there too.</p>
      </IntroTextWrapper>
      <ContactForm />
      </InnerWrapper>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  border-radius: 3px;
`

const IntroTextWrapper = styled.div`

`
const InnerWrapper = styled.div`
  max-width: 960px;
  background-color: #3C444C;
  padding: 2rem;
  margin: 1rem;
`

const FancyText = styled.h2`
  font-family: 'Montserrat', sans-serif;
  padding: 1rem 0rem;
  font-size: 50px;
  line-height: 70px;
  background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default Contact