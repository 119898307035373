import React from 'react';
import styled, { keyframes } from 'styled-components';

interface LoadingSpinnerProps {
  size?: number;
  color?: string;
}

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ size: number; color: string }>`
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 6px dotted ${props => props.color};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 40, color = '#000000' }) => {
  return <Spinner size={size} color={color} />;
};

export default LoadingSpinner;
