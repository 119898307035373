import React, { useState } from 'react';
import SurfSpots from '../../Data/SurfSpots_New.json';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

const SpotSelect = ({ setShowModal, noSubmit, className }) => {

  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState('');
  const [counties, setCounties] = useState([]);
  const [states, setStates] = useState([]);
  const [surfSpots, setSurfSpots] = useState([]);
  const [selectedSurfSpot, setSelectedSurfSpot] = useState('');
  const { surfspot, state, continent, country, county, photographers } = useParams();
  const navigate = useNavigate();

  const continents = SurfSpots.map(surfSpot => surfSpot.continent);
  const uniqueContinents = continents.filter((continent, index) => continents.indexOf(continent) === index);

  const handleSubmit = (selectedSurfSpot) => {
    const onNewAlbumUrl = `/newalbum/${selectedContinent}/${selectedCountry}/${selectedState}${selectedCounty ? `/${selectedCounty}` : ''}/${selectedSurfSpot}`;
    const onFindPhotosPageUrl = `/${selectedContinent}/${selectedCountry}/${selectedState}${selectedCounty ? `/${selectedCounty}` : ''}/${selectedSurfSpot}`;
    const photographerSpotUrls = `/photographers/${selectedContinent}/${selectedCountry}/${selectedState}${selectedCounty ? `/${selectedCounty}` : ''}/${selectedSurfSpot}`;

    if (window.location.pathname.includes('/newalbum')) {
      navigate(onNewAlbumUrl);
    } else  if (photographers) {
      console.log("photographer param true");
      navigate(photographerSpotUrls);
    } else {
      navigate(onFindPhotosPageUrl);
    }
  
    setShowModal(false);
  }
  
  const handleContinentChange = (event) => {
    const selectedContinent = event.target.value;
    setSelectedContinent(selectedContinent);
    setSelectedCountry('');
    setSelectedState('');
  
    const selectedCountries = SurfSpots.find(surfSpot => surfSpot.continent === selectedContinent)?.countries ?? [];
    setCountries(selectedCountries);
    setStates([]);
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
    setSelectedState('');
    setSelectedSurfSpot('');
    setSurfSpots([]);
    
    const selectedStates = SurfSpots
      .find(surfSpot => surfSpot.continent === selectedContinent)
      ?.countries.find(country => country.name === selectedCountry)
      ?.states.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    setStates(selectedStates);
  };
  

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
  
    const selectedCountryObj = SurfSpots
      .find(surfSpot => surfSpot.continent === selectedContinent)
      ?.countries.find(country => country.name === selectedCountry);
  
    const selectedCounties = selectedCountryObj?.states.find(state => state.name === selectedState)?.counties;
    if (selectedCounties) {
      setCounties(selectedCounties);
      setSelectedCounty('');
    } else {
      setCounties([]);
      setSurfSpots([]);
      setSelectedCounty('');
      setSelectedSurfSpot('');
    }
  
    const selectedSurfSpots = selectedCountryObj
      ?.states.find(state => state.name === selectedState)
      ?.surfSpots ?? [];
    setSurfSpots(selectedSurfSpots);
  };
  
  const handleCountyChange = (event) => {
    const selectedCounty = event.target.value;
    setSelectedCounty(selectedCounty);
  
    const selectedSurfSpots = SurfSpots
      .find(surfSpot => surfSpot.continent === selectedContinent)
      ?.countries.find(country => country.name === selectedCountry)
      ?.states.find(state => state.name === selectedState)
      ?.counties.find(county => county.name === selectedCounty)
      ?.surfSpots ?? [];
    setSurfSpots(selectedSurfSpots);
  };  

  const handleSurfSpotChange = (event) => {
    const selectedSurfSpot = event.target.value;
    setSelectedSurfSpot(selectedSurfSpot);
  };

  return (
    <Wrapper className={className}> 
      <label>
        <h4>Continent</h4>
        <select value={selectedContinent} onChange={handleContinentChange}>
          <option value=""></option>
          {uniqueContinents.map(continent => (
            <option key={continent} value={continent}>{continent}</option>
          ))}
        </select>
      </label>
      {countries.length > 0 && selectedContinent && (
        <label>
          <h4>Country</h4>
          <select value={selectedCountry} onChange={handleCountryChange}>
            <option value=""></option>
            {countries.map(country => (
              <option key={country.name} value={country.name}>{country.name}</option>
            ))}
          </select>
        </label>
      )}
      {states.length > 0 && selectedCountry && (
        <label>
          <h4>State</h4>
          <select value={selectedState} onChange={handleStateChange}>
            <option value=""></option>
            {states.map(state => (
              <option key={state.name} value={state.name}>{state.name}</option>
            ))}
          </select>
        </label>
      )}
      {counties.length > 0 && selectedCountry && (
        <label>
          <h4>County</h4>
          <select value={selectedCounty} onChange={handleCountyChange}>
            <option value=""></option>
            {counties.map(county => (
              <option key={county.name} value={county.name}>{county.name}</option>
            ))}
          </select>
        </label>
      )}
      {surfSpots.length > 0 && selectedState  && (
        <label>
          <h4>Surf Spot</h4>
          <select value={selectedSurfSpot} onChange={handleSurfSpotChange}>
            <option value=""></option>
            {surfSpots.sort().map(surfSpot => (
              <option key={surfSpot} value={surfSpot}>{surfSpot}</option>
            ))}
          </select>
        </label>
      )}
      {noSubmit ? null : <SubmitSpotBtn title="Select a spot to submit" disabled={!selectedSurfSpot} onClick={() => handleSubmit(selectedSurfSpot)}>SUBMIT</SubmitSpotBtn>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #CBCFD4;
  
  label {
    display: flex;
    flex-direction: column;
    padding: .25rem 0rem;

    h4 {
      padding-bottom: 7px;
      font-size: 12px;
    }
  }

  select {
    background-color: #2e343a;
    color: #CBCFD4;
    padding: 0.5rem;
    border-radius: 3px;
  }
`

const SubmitSpotBtn = styled.button`
  font-weight: 700;
  margin: 1.5rem 0rem;
  width: 125px;
  height: 35px;
  font-size: 10px;
  color: white;
  font-weight: 700;
  border-radius: 7px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);

  :disabled {
    background: gray;
  }
`

export default SpotSelect;