import React from 'react';
import styled from 'styled-components';
import SecondaryButton from '../../Components/Buttons/SecondaryButton';
import { Link } from 'react-router-dom';

interface SideBySideProps {
  title: string;
  bodyText: string;
  button: boolean;
  buttonText?: string;
  imageUrl: string; // New prop for the image URL
  flipCard?: boolean,
  buttonUrl: string
}

function SideBySide(props: SideBySideProps) {
  return (
    <Wrapper className="flex flex-col md:flex-row items-center">
      { props.flipCard ? 
      <>
        <div className="md:w-1/2 text-right w-full">
          {props.imageUrl && <img src={props.imageUrl} alt="Side by side" className="w-full h-auto" />}
        </div>  
        <div className="md:w-1/2 text-right w-full">
          <h3 className="text-xl md:text-2xl lg:text-3xl text-right md:text-right mb-4">{props.title}</h3>
          <p className="text-sm md:text-base text-right md:text-right">{props.bodyText}</p>
          { props.button && <Link to={props.buttonUrl}><SecondaryButton className="btnstyle">{props.buttonText}</SecondaryButton></Link> }
        </div>
      </>
      :
      <>
        <div className="md:w-1/2 w-full">
          <h3 className="text-xl md:text-2xl lg:text-3xl text-center md:text-left sm:text-left mb-4">{props.title}</h3>
          <p className="text-sm md:text-base text-center md:text-left sm:text-left">{props.bodyText}</p>
          {props.button && <Link to={props.buttonUrl}><SecondaryButton className="btnstyle">{props.buttonText}</SecondaryButton></Link> }
        </div>
        <div className="md:w-1/2 w-full">
          {props.imageUrl && <img src={props.imageUrl} alt="Side by side" className="w-full h-auto" />}
        </div>
      </>
    }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  /* padding: 0px 25px; */

  img {
    border-radius: 5px;
    padding: 25px;
  }
  h3 {
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Righteous';
    font-size: 44px;
    background: -webkit-linear-gradient(60deg, #f27a54, #a154f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    line-height: 20px;
    padding: 8px 0px 28px 0px;
  }
  .btnstyle {
    text-transform: uppercase;
    width: 150px;
    height: 40px;
  } 

  @media screen and (max-width: 975px) {
    padding: 0px 25px;
  }

  @media screen and (max-width: 720px) {
    text-align: left;

    h3, p {
      text-align: left;
    }

    h3 {
      line-height: 100%;
    }

    img {
      padding-top: 0px;
    }
  }
`;

export default SideBySide;
