import { getDocs, getFirestore, query, where, collection } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PhotographerCard from '../../Components/PhotographerCard/PhotographerCard';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DashboardInfoBar from '../../Components/DashboardPhotographerInfoBar/DashboardPhotographerInfoBar';
import SpotBar from '../../Components/SpotBar/SpotBar';

function Dashboard_Photographers() {

  const [availableFlmrs, setAvailableFlmrs] = useState<any[]>([]);
  const firestoredb: any = getFirestore();
  const MemoizedPhotographerCard: any = React.memo(PhotographerCard);
  const { continent, country, state, county, surfspot }: any = useParams();
  
  useEffect(() => {
    const fetchData = async () => {
      if (surfspot) {
        try {
          const q = query(collection(firestoredb, "users"), where("flmr_locations", "array-contains", surfspot));
          const querySnapshot = await getDocs(q);
          const flmrs: any[] = [];
          querySnapshot.forEach((doc) => {
            flmrs.push(doc.data());
          });
          setAvailableFlmrs(flmrs);
        } catch (error) {
          console.error("Error fetching users data:", error);
        }
      }
    };

    fetchData();
    // Return a cleanup function if needed
    return () => {
      // Perform any necessary cleanup here
    };
  }, [surfspot, firestoredb]);

  const filteredFlmrs = availableFlmrs.filter((item) => item.flmr_available);

  return (
    <>
    <SpotBar selectedSpot={surfspot} />
    <UserListWrapper>
      <DashboardInfoBar surfspot={surfspot} />
      {/* <HRFancy /> */}
      <UserItemWrapper>
        {/* No albums message */}
        { filteredFlmrs.length > 0 ? null : 
          <NoSpotsWrapper>
            <h2>There are not any available FLMRs for this spot.</h2>
          </NoSpotsWrapper> }
        {/* Album Cards Displayed Here */}
        <CardWrapper className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
            {filteredFlmrs.map((item: any, index: any) => {
              // console.log(item);
              return <MemoizedPhotographerCard
                        key={index}
                        album_continent={item.album_continent}
                        album_country={item.album_country}
                        album_county={item.album_county}
                        album_default_banner={item.album_default_banner}
                        album_description={item.album_description}
                        album_media_count={item.album_media_count}
                        album_media_price={item.album_media_price}
                        album_name={item.album_name}
                        album_owner_id={item.album_owner_id}
                        album_owner_username={item.album_owner_username}
                        album_slug={item.album_slug}
                        album_spot={item.album_spot}
                        album_state={item.album_state}
                        username={item.username}
                        album_upload_date={item.album_upload_date}
                        session_date={item.session_date}
                        session_time={item.session_time}
                        surf_spot={surfspot}
                        continent={continent}
                        country={country}
                        state={state}
                        county={county}
                        flmr_listing_banner_url={item.flmr_listing_banner_urls[0]}
                        flmr_hourly_rate={item.flmr_hourly_rate}
                        flmr_media_type={item.flmr_media_type}
                        profile_banner_image={item.flmr_listing_banner_urls[0]}
                        user_id={item.user_id}
                      />
            })
          }
        </CardWrapper>
      </UserItemWrapper>
    </UserListWrapper>
    </>
  )
}

const UserItemWrapper = styled.div`
  padding-top: 1rem;
`
const NoSpotsWrapper = styled.div`
  color: #30363d;
  background-color: #3C444C;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  border-radius: 4px;
`

const CardWrapper = styled.div`
  position: relative;
  gap: 1rem;
  border-radius: 3px;
`

const UserListWrapper = styled.div`
  padding: 15px 0px 20px 0px;
  margin: 0rem 1.5rem;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    font-size: 24px;
    font-weight: 700;
  }

  .user {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 1em;
    border-bottom: 1px solid #bfbfbf;
    align-items: center;
  }
`

export default Dashboard_Photographers;
