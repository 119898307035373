import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import PickDate from '../../Components/PickDate/PickDate';
import ReservationSummary from '../../Components/ReservationSummary/ReservationSummary';
import { toast } from 'react-toastify';
import IconStar from '../../Components/Icons/IconStar';
import useEmblaCarousel from 'embla-carousel-react';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import EmblaCarousel from '../../Components/Carousel/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel'
import { useUserAuth } from '../../Context/UserAuthContext';

function Cart() {

  const db = getFirestore();
  const [emblaRef] = useEmblaCarousel();
  const OPTIONS: EmblaOptionsType = {}
  const hours = [1, 2, 3, 4, 5, 6, 7, 8];
  const media_type = ["Photography", "Video", "Drone"];
  // let { user } = useUserAuth();
  const [currentProfileData, setCurrentProfileData] = useState(null);
  const [bookingLength, setBookingLength] = useState(null);
  const [preTaxTotal, setPreTaxTotal] = useState(Number);
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { continent, country, state, county, username, userid, surfspot }: any = useParams();
  const [activeHour, setActiveHour] = useState(null);
  const [transactionFee, setTransactionFee] = useState(Number);
  
  // Handle hour selection, active class and value state
  const handleHourButtonClick = (hour: number) => {
    setActiveHour(hour);
    setBookingLength(hour);
  };

  const handleMediaTypeClick = (mediaType: string) => {
    setSelectedMediaType(mediaType);
  }

  //  Calculate the transaction fee 
  useEffect(() => {
    if (bookingLength && currentProfileData && currentProfileData.hourly_rate !== null) {
      let fee = bookingLength * currentProfileData?.flmr_hourly_rate;
      let feePercentage = fee * 0.14;
      setTransactionFee(feePercentage);
    }
  }, [bookingLength]);

  // Calculate the pretax cost
  useEffect(() => {
    if (currentProfileData && currentProfileData.bookingLength !== null) {
      let sessionPrice = bookingLength * currentProfileData?.flmr_hourly_rate;
      console.log(sessionPrice);
      setPreTaxTotal(sessionPrice);
    }
  }, [bookingLength]);

  // Fetch the current users profile of the page you're on
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const q = query(collection(db, 'users'), where('user_id', '==', userid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setCurrentProfileData(userData);
        } else {
          console.log('No matching documents.');
        }
      } catch (err) {
        console.error('Error fetching user:', err);
      }
    }
    fetchUser();
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper>
        <h1>{username}</h1>
      </HeaderWrapper>
      <HRFancy />
      <CarouselWrapper>
      {currentProfileData?.flmr_listing_banner_urls && (
        <StyledCarouselWrapper className="mb-4">
          <EmblaCarousel slides={currentProfileData?.flmr_listing_banner_urls} options={OPTIONS} />
        </StyledCarouselWrapper>
      )}
      </CarouselWrapper>
      <div className="md:grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-8 xl:grid-cols-8">
        <div className="col-span-5 profile-wrapper">
          <div className="flex justify-between items-center divider">
            <div>
              <h2>{username}</h2>
              <p>{surfspot}</p>
            </div>
            <div>
              <h2 className='pretty-text'>
                ${currentProfileData ? currentProfileData.flmr_hourly_rate : ''} / Hour
              </h2>
            </div>
          </div>
          
          <MediaTypeWrapper className="divider">
            {currentProfileData?.flmr_media_type.map((media: any) => (
              <button
                key={media}
                className={`hour-btn px-4 text-white ${selectedMediaType === media ? 'active' : ''}`}
                onClick={() => handleMediaTypeClick(media)}
              >
                <p>{media}</p>
              </button>
            ))}
          </MediaTypeWrapper>
          <div className="flex divider">
            <IconStar />
            <p className="pl-2">
              {currentProfileData && currentProfileData.user_rating ? currentProfileData.user_rating : 'No Rating Yet'}
            </p>
          </div>
          <div className="divider">
            <p>{currentProfileData?.flmr_about}</p>
          </div>
          <div className="divider flex-col">
            <h3 className="mb-4">SELECT YOUR SESSION DATE & START TIME</h3>
            <PickDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </div>
          <LengthSelectWrapper className="divider flex-col">
            <h3 className="mb-4">HOW MANY HOURS</h3>
            <div className="flex">
              {hours.map((hour) => (
                <button
                  key={hour}
                  className={`hour-btn ${activeHour === hour ? 'active' : ''}`}
                  onClick={() => handleHourButtonClick(hour)}
                >
                  <p>{hour}</p>
                </button>
                ))}
            </div>
          </LengthSelectWrapper>
        </div>
        {/* Right Side Column */}
        <div className="col-span-3">
          <ReservationSummary
            hourly_rate={currentProfileData?.flmr_hourly_rate}
            booking_user_username="current logged in username"
            booking_user_id="current logged in user id"
            preTaxTotal={preTaxTotal} 
            mediaType={selectedMediaType}
            sessionDate={selectedDate}
            transactionFee={transactionFee} 
            orderTotal={0} 
            bookingLength={bookingLength}
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 1rem;

  img {
    object-fit: cover;
    width: 100%;
  }

  .fullwidth {
    width: 100%;
    height: 250px;
  }

  .content {
    justify-content: space-between;
  }

  h1 {
    color: #CBCFD4;
  }

  h2 {
    font-family: 'Montserrat';
    color: white;
    font-size: 24px;
    font-weight: 700;
  }

  h3 {
    font-family: 'Montserrat';
    color: white;
    font-size: 16px;
    font-weight: 700;
  }

  .profile-wrapper {
    background-color: #3C444C;
    border-radius: 3px;
  }

  .pretty-text {
    font-family: 'Montserrat',sans-serif;
    background: -webkit-linear-gradient(60deg,#F27A54,#A154F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  .divider {
    padding: 1.5rem 1rem;
    display: flex;
    border-bottom: 1px solid #30363D;
  }

  .hour-btn {
    position: relative;
    color: #CBCFD4;
    margin-right: 0.5rem;
    border-radius: 3px;
    display: flex;
    background-color: #30363d;
    height: 35px;
    min-width: 35px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease;
  }

  .hour-btn::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color 0.1s ease;
  }

  .hour-btn:hover::before {
    border-color: #f07957;
  }

  .hour-btn.active::before {
    border-color: #f07957;
  }

  .hour-btn.active:hover::before {
    border-color: #f07957;
  }

`

const CarouselWrapper = styled.div`
  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
  }
`

const LengthSelectWrapper = styled.div`
  input {
    background-color: #30363d;
    color: #CBCFD4;
    padding: .25rem 1rem;
    border-radius: 3px;
    max-width: 195px;
  }

`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C444C;
  height: 69px;

  h1 {
    font-family: 'Montserrat';
    color: #CBCFD4;
    padding: 1rem;
    font-size: 24px;
    font-weight: 900;
  }
`

const HRFancy = styled.hr`
  background: linear-gradient(to right, #F27A54, #A154F2);
  height: 5px;
  border-top: none;
  margin-bottom: 1rem;
`

const MediaTypeWrapper = styled.div`
  display: flex;
  padding: 1rem 0rem 0rem 0rem;

  .chip {
    padding: .25rem 1rem;
    margin-right: 1rem;
    border: 1px solid gray;
    border-radius: 3px;
    font-size: 12px;
  }
  
`

const NoSpotsWrapper = styled.div`
  color: #30363d;
  background-color: #3C444C;
  display: flex;
  padding: 5rem 2rem;
  justify-content: center;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 1rem;
`

const CheckoutMessage = styled.div`
  background-color: #ff000070;
  padding: 1rem 0.5rem;
  margin-bottom: 15px;
  border-radius: 3px;
  height: auto;
  display: flex;
  align-items: center;

  p {
    padding-left: 15px;
  }
`

const StyledCarouselWrapper = styled.div`
  .embla__controls {
    display: flex;
    align-items: center;
  }

  .embla__slide {
    border: 2px solid #30363d;
  }

  .embla__slide img {
    width: 100%;
    height: 250px;
  }

  .embla__button {
    height: 100%;
    width: 36px;
    background-color: #3c444c;
    color: #CBCFD4;
    border-radius: 50%;
    padding: 12px;
    margin-right: 10px;
  }

  .embla__button:hover {
    transition: .3s ease-in-out;
    background-color: #3c444cb7;
  }

  .embla__dot {
    margin-left: 10px;
    height: 14px;
    width: 14px;
    border: 2px solid #040404;
    border-radius: 50%;
  }
  
  .embla__dot--selected {
    border: 2px solid white;
    background-color: white;
  }
`
export default Cart