import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useUserAuth } from '../../Context/UserAuthContext';
import SecondaryButton from '../../Components/Buttons/SecondaryButton';
// import videoBg from '../../Images/home_videobg.jpg';
import styled from 'styled-components';
import Footer from '../../Components/Footer/Footer';
import bannerSplashScreen from '../../Images/home/home-bg1.png';
import Carousel from '../../Components/Carousel/Carousel';
import PopularSpots from '../../Components/PopularSpots/PopularSpots';
import Tabs from '../../Components/Tabs/Tabs';
import InfoCard from '../../Components/InfoCard/InfoCard';
import SideBySide from '../../Components/SideBySide/SideBySide';

// Recently Uploaded Images
import RecentAlbum6 from '../../Images/AlbumBanners/recent6.jpeg';
import RecentAlbum7 from '../../Images/AlbumBanners/recent7.jpeg';
import RecentAlbum8 from '../../Images/AlbumBanners/recent8.jpeg';
import RecentAlbum9 from '../../Images/AlbumBanners/recent9.jpeg';
import RecentAlbum10 from '../../Images/AlbumBanners/recent10.jpeg';
import RecentAlbum11 from '../../Images/AlbumBanners/recent11.jpeg';
import RecentAlbum12 from '../../Images/AlbumBanners/recent12.jpeg';

import img1 from '../../Images/surfphotographer-min.png'
import img2 from '../../Images/albums_img-min.png';
import img3 from '../../Images/albums_img-find-min.png'

function Home() {

  const carouselImages = [
    { src: RecentAlbum10, photographer: 'BenMaserMedia', photoCount: 49, href: 'https://www.flmr.app/albums/qDFsWXS7b8YW1JuELkAHwSz3ppz1/Scripps%20Pier%20-%20La%20Jolla/3-31-2024%20Scripps%20(Part%206)' },
    { src: RecentAlbum11, photographer: 'BenMaserMedia', photoCount: 81, href: 'https://www.flmr.app/albums/qDFsWXS7b8YW1JuELkAHwSz3ppz1/Scripps%20Pier%20-%20La%20Jolla/3-31-2024%20Scripps%20(Part%204)' },
    { src: RecentAlbum12, photographer: 'BenMaserMedia', photoCount: 74, href: 'https://www.flmr.app/albums/qDFsWXS7b8YW1JuELkAHwSz3ppz1/Scripps%20Pier%20-%20La%20Jolla/3-31-2024%20Scripps%20(Part%205)' },
    { src: RecentAlbum9, photographer: 'Cestudios', photoCount: 53, href: "https://www.flmr.app/albums/AX7AAVJuLMWwEh4VaRFAZQmj1r63/Bailey's%20Beach/Hurricane%20Lee" },
    { src: RecentAlbum8, photographer: 'SamCoxStudios', photoCount: 116, href: 'https://www.flmr.app/albums/i37WWISR9sQdsPIZLLN81fgLlI73/Ruggles/Ruggles%20-%209.19.23' },
    { src: RecentAlbum7, photographer: 'saltyviewsbyem', photoCount: 56, href: 'https://www.flmr.app/albums/17kJxoJ8vXhUX6LCYA1mSZ01JO13/2nd%20Beach%20(Sachuest%20Beach)/aug31%20am' },
    { src: RecentAlbum6, photographer: 'saltyviewsbyem', photoCount: 45, href: 'https://www.flmr.app/albums/17kJxoJ8vXhUX6LCYA1mSZ01JO13/2nd%20Beach%20(Sachuest%20Beach)/aug31%20pm' },
  ];

  const [flipCard, setFlipCard] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setFlipCard(false);
      } else {
        setFlipCard(true);
      }
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Content>
      <div className="hero-wrapper px-4">
        <div className="hero-text">
          <h1 className="mb-6 gradient-text text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl">HIRE SURF <br />PHOTOGRAPHERS</h1>
          <p style={{lineHeight: '18px'}}>Find and hire top surf photographers to capture your best moments on the waves, with stunning photos delivered right to you.</p>
          <Link to={'/photographers/North America/United States/Rhode Island/Misquamicut'}><SecondaryButton className="login-btn mt-8">FIND PHOTOGRAPHERS</SecondaryButton></Link>
        </div>
      </div>
      <CardWrapper className="max-w-screen-3xl mx-auto mt-20 mb-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 maxwidth">
          <InfoCard 
            title={'Hire Photographers'} 
            bodyText={'Whether youre going on a surf trip or theres a swell coming at home. Weve got your spot listed (no secret spots blown up here though).'} 
          />
          <InfoCard 
            title={'Any Surf Spot'} 
            bodyText={'Choose where you will surf, browse available photographers and send a booking request.'} 
          />
          <InfoCard 
            title={'Get Photos'} 
            bodyText={'Meet up at your selected date and time and go surf. Don\'t worry about relying on that rewind clip anymore..'} 
          />
        </div>
      </CardWrapper>
      <PopularSpotsWrapper className="max-w-screen-3xl mx-auto mb-10">
        <div className="maxwidth">
          <PopularSpots />
        </div>
      </PopularSpotsWrapper>
      <div className="maxwidth mb-10">
        <SideBySide 
          title={'Start Booking Clients'}
          bodyText={'Create a free account and start accepting bookings as a surf photographer, videographer, or drone pilot, and connect with surfers looking to capture their surfing.'}
          button={true}
          buttonText='Get Listed'
          buttonUrl='/signup'
          imageUrl={img1}
          flipCard={false}
        />
        <SideBySide 
          title={'Browse Photographers'}
          bodyText={'Thousands of surf spots, albums, photographers and surfers using FLMR around the world.'}
          button={true}
          buttonText='Find Photographers'
          buttonUrl='/photographers/North America/United States/Rhode Island/Misquamicut'
          imageUrl={img2}
          flipCard={flipCard}
        />
        <SideBySide 
          title={'Get Your Photos'}
          bodyText={'Find professional surf photographers worldwide to capture your next surf session.'}
          button={true}
          buttonText='Find Photographers'
          buttonUrl='/photographers/North America/United States/Rhode Island/Misquamicut'
          imageUrl={img3}
          flipCard={false}
        />
      </div>
      {/* <div className="mb-20" style={{maxWidth: '100%'}}>
        <h3 className="pretty-text pb-8 text-center">RECENT UPLOADS</h3>
        <Carousel images={carouselImages}/>
      </div>  */}
      <Footer />
    </Content>
  )
}

const CardWrapper = styled.section`
  padding: 0px 25px;
`

const PopularSpotsWrapper = styled.section`
  padding: 0px 25px;
`

const SectionHowItWorks = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0rem 0rem 6rem 0rem;
  /* background-color: #3c444c; */

  h5 {
    color: #CBCFD4;
  }

  h4 {
    font-size: 14px;
    text-align: left;
  }

  .card:hover {
    transform: translateY(-5px);
  }

  .card a {
    color: #F27A54;
  }

  .card h5 {
    padding-bottom: 15px;
  }

  .icon {
    width: 48px;
    padding: 0rem 0rem 2rem 0rem;
  }

  @media screen and (max-width: 525px) {
    #videoSection {
      flex-direction: column;
    }
  }
`

const Content = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 80px;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;

  .maxwidth {
    max-width: 960px;
  }

  @media screen and (max-width: 720px) {
    h5 {
      font-size: 20px;
    }
  }

  .pretty-text {
    font-family: 'Montserrat',sans-serif;
    font-size: 44px;
    background: -webkit-linear-gradient(60deg,#F27A54,#A154F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;

    @media screen and (max-width: 720px) {
      font-size: 36px;
    }
  }

  .hero-wrapper {
    background-image: url(${bannerSplashScreen});
    padding: 80px 25px;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hero-text {
    width: 960px;
  }

  .intro-sub {
    padding: 25px 50px 25px 0px;
    font-size: 20px;

    @media screen and (max-width: 400px) {
        font-size: 14px;
        line-height: 1.5rem;
    }

  }

  .gradient-text {
    background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 64px;
    background: -webkit-linear-gradient(60deg, #F27A54, #A154F2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .login-btn {
    width: 150px;
    height:40px;
  }

  @media screen and (max-width: 720px) {
    .intro-wrapper {
      flex-direction: column;
      
    }
    #hero {
      margin-top: 4rem;
    }
    h2 {
      font-size: 36px;
    };
    h3 {
      font-size: 32px;
    }
  }

`

const VideoSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 8rem 0rem;

  iframe span {
    display: none;
  }

  .mobile {
    width: 100%;
  }
 
  h5 {
    line-height: 34px;
  }

  p {
    color: #CBCFD4;
  }

  .signup-primary {
    width: 150px;
    height: 45px; 
    border: 1px solid white;
    font-size: 10px;
    font-weight: 700;
    color: white;
    border-radius: 7px;
    letter-spacing: 1px;
  }

  @media screen and (max-width: 525px) {
    #videoSection {
      flex-direction: column;
    }
    .left, .right {
      box-shadow: none;
    }
    .mobile {
      height: 230px;
    }
  }
`;

export default Home
