import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useUserAuth } from '../../Context/UserAuthContext';

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const { user, loading } = useUserAuth();

  // Define the admin UID
  const adminUID = 'GOTzQ3ygGsbFA903pVoJBFp54uJ2';

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner size={140} color="#F27A54" />
      </div>
    );
  }

  if (!user || user.uid !== adminUID) {
    return <Navigate to="/notfound" />;
  }

  return <>{children}</>;
};

export default AdminRoute;
