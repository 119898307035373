import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID 
}

// const provider = new GoogleAuthProvider();
// This only handles the google login 
// async function google_signin() {
//   firebase.auth()
//   .signInWithPopup(provider)
//   .then((result) => {
//     var credential = result.credential;
//     var token = credential.accessToken; // This gives you a Google Access Token. You can use it to access the Google API.
//     var user = result.user; // The signed-in user info.
//     // After user signs in, redirect to the Dashboard component page
//     window.location.href = "/dashboard";
//   }).catch((error) => {
//     // Handle Errors here.
//     var errorCode = error.code;
//     var errorMessage = error.message; // We can log 'errorMessage' if needed
//     var email = error.email; // The email of the user's account used.
//     var credential = error.credential; // The firebase.auth.AuthCredential type that was used.
//   });
// }
// exporting this google signin function
// export default google_signin;

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);


