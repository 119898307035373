import React from 'react'
import styled from 'styled-components'

function IconPlus(props) {
  return (
    <Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill={props.fill} className={props.class} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  transition: fill .3s ease-in-out;  
  svg {
    width: 16px;
    fill: #30363d;
  }
`
export default IconPlus