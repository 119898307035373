import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import AlbumCard from '../AlbumCard/AlbumCard';
import { toast } from 'react-toastify';
import { getStorage, getDownloadURL } from 'firebase/storage';
import { ref as sRef } from 'firebase/storage';
import { query, collection, where, getDocs, getFirestore } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';

function Profile() {
  
  const [albums, setAlbums] = useState([]);
  const { owner, username } = useParams();
  const [avatar, setAvatar] = useState();
  const storage = getStorage();
  const firestoredb = getFirestore();

  // Get users profile image, if it exists
  useEffect(() => {
    const storageRef = storage; 
    getDownloadURL(sRef(storageRef, `user_images/${owner}/avatar/user_avatar.png`))
        .then((url) => {
          setAvatar(url);
        })
        .catch((error) => {
          console.log("Album Card Avatar Error: ", error);
        });
  }, []);

  // Get users albums
  useEffect(async () => {
    const q = query(collection(firestoredb, "albums"), where("album_owner_id", "==", owner));
    const querySnapshot = await getDocs(q);
    const usersAlbums = [];
    querySnapshot.forEach((doc) => {
      usersAlbums.push(doc.data());
    });
    setAlbums(usersAlbums);
  }, []);

  function handleClick() {
    toast.warning("Feature coming soon...", {
      theme: "dark"
    });
  }
  
  return (
    <ProfilePage className="w-full mt-6 px-6">
      {/* Profile Intro */}
      <ProfileIntro className="shadow-md">

        <div className="banner">
          <ProfileImageWrapper>
            <img src={avatar} />
          </ProfileImageWrapper>
        </div>

        <div className='px-8' style={{marginTop: '7rem'}}>
          <h2>{username}</h2>
          <div className="btn-wrapper mt-4 mb-8">
            <button onClick={handleClick} className="btn-message mr-4">Message</button>
            <ChangeLocationBtn onClick={handleClick}>Share profile</ChangeLocationBtn>
          </div>
        </div>
      </ProfileIntro>

      <Section className="shadow-md mt-4 mb-8">
        <h2 className="pt-6 pb-6">Albums</h2>
        {/* Users albums here */}
        {/* Use same method as 'My Albums' page */}
        <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3 md:grid-cols-2">
          { albums.map((item, index) => {
            // console.log(item);
            return <AlbumCard
                      key={index}
                      album_continent={item.album_continent}
                      album_country={item.album_country}
                      album_county={item.album_county}
                      album_default_banner={item.album_default_banner}
                      album_description={item.album_description}
                      album_media_count={item.album_media_count}
                      album_media_price={item.album_media_price}
                      album_name={item.album_name}
                      album_owner_id={item.album_owner_id}
                      album_owner_username={item.album_owner_username}
                      album_slug={item.album_slug}
                      album_spot={item.album_spot}
                      album_state={item.album_state}
                      album_upload_date={item.album_upload_date}
                      session_date={item.session_date}
                      session_time={item.session_time}
                  />
          })}
        </div>
      </Section>
    </ProfilePage>
  );
}

const ChangeLocationBtn = styled.button`
  width: 125px;
  height: 35px;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  background: linear-gradient(90deg,#F27A54 0%,#A154F2 100%);
`

const ProfileImageWrapper = styled.div`
  display: flex;
  height: 175px;

  img {
    position: relative;
    object-fit: cover;
    bottom: -105px;
    left: 2rem;
    background-color: #3c444c;
    height: 148px;
    max-width: 148px;
    min-width: 148px;
    min-height: 148px;
    border-radius: 50%;
    padding: 6px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3c444c;
  border-radius: 7px;
  padding: 0rem 2rem 2rem 2rem;

  h2 {
    color: #CBCFD4;
  }
`

const ProfileIntro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3c444c;
  border-radius: 7px;

  h2 {
    color: #CBCFD4;
  }

  .btn-message {
    font-family: 'Montserrat';
    width: 125px;
    height: 35px;
    display: flex;
    border-radius: 4px;
    border: 1px solid #CBCFD4;
    color: #CBCFD4;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }

  .btn-wrapper {
    display: flex;
  }
`

const ProfilePage = styled.div`

  // This is hacky and we shouldn't do this, but lets just get it done

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5d6165;
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5d6165;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    color: #5d6165;
  }

  input {
    font-size: 12px;
    color: #CBCFD4;
  }

  .banner {
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(90deg, rgb(242, 122, 84) 0%, rgb(161, 84, 242) 186.42%);
  }

  .userPhoto {
    background-color: gray;
    border-radius: 50%;
  }

  .borderCustom {
    border-bottom: 1px solid #30363d;
  }
`

export default Profile;
