import React from 'react'
import styled from 'styled-components';
import IconTrash from '../Icons/IconTrash';
import { doc, deleteDoc, getFirestore } from 'firebase/firestore';
import { useUserAuth } from '../../Context/UserAuthContext';
import { toast } from 'react-toastify';

interface CartItemCardProps {
  id: string,
  albumName: string,
  itemName: string,
  itemFileType: string,
  itemCost: number
  itemThumbnail: string,
  owner: string,
  photographer: string,
  key: any
}

function CartItemCard(props: CartItemCardProps) {

  const db = getFirestore();
  let { user } = useUserAuth();   
  
  // Handle removing item from users cart
  const handleClick = async () => {
    try {
      await deleteDoc(doc(db, 'carts', user.uid, 'items', props.id));
      toast.success("Item removed from cart", {
        theme: "dark"
      });
    } catch (error) {
      toast.error(error, {
        theme: "dark"
      });
    }
  };

  return (
    <Wrapper className='shadow-lg'>
      <InnerWrapper className="flex-col xs:flex-row sm:flex-row">
        <img src={props.itemThumbnail} className="md:w-fit"/>
        <div className="content-wrapper sm:ml-4 md:ml-4">
          <div className="content">
            <p><b>Photographer: </b>{props.photographer}</p>
            <p><b>Album: </b>{props.albumName}</p>
            <p><b>ID:</b> {props.id}</p>
            <h4>DOWNLOAD FORMAT</h4>
            <div className="filetypes mt-2">
              <div className="filetype-indicators mr-2">PNG</div>
              <div className="filetype-indicators">JPG</div>
            </div>
            <p>${props.itemCost}</p>
          </div>
          <div onClick={handleClick} className="delete flex flex-col mt-4 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
            <IconTrash />
          </div>
        </div>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1rem;
  background-color: #3C444C;
  border-radius: 7px;
  margin-bottom: 1rem;
`

const InnerWrapper = styled.div`
  display: flex;

  .content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .content p {
    line-height: 18px;
  }
  
  .filetypes {
    display: flex;
    flex-direction: row;
  }

  .filetype-indicators {
    background-color: #30363D;
    padding: 7px;
    color: #CBCFD4;
    font-size: 10px;
    border-radius: 3px;
  }

  img {
    height: 140px;
    object-fit: cover;
    border-radius: 3px;
  }
`

export default CartItemCard